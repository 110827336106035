import AllotmentsEditorRoomDay from './AllotmentsEditorRoomDay';
import moment from 'moment';
import AllotmentsEditorRoomSelectButton from "./AllotmentsEditorRoomSelectButton";

export default function AllotmentsEditorRoom({
  organizationId,
  room,
  currentMonth,
  daysInMonth,
  toggleSelect,
  selectAll,
  deselectAll,
  isSelected,
  showSharedAllotments,
}) {
  const days = [...Array(daysInMonth)].map((_, i) => moment().month(currentMonth).date(i + 1));

  return (
    <div className="py-4 px-4">
      <div className="flex space-x-3 items-center">
        <div className="w-52 font-medium flex justify-between items-center">
          <div>
            <i className="far fa-bed mr-1" />
            {room.name}
          </div>

          <AllotmentsEditorRoomSelectButton
            selectAll={() => selectAll()}
            deselectAll={() => deselectAll()}
          />
        </div>

        {days.map((date, i) => (
          <AllotmentsEditorRoomDay
            key={i}
            organizationId={organizationId}
            room={room}
            date={date}
            onClick={() => toggleSelect(date, room)}
            selected={isSelected(date, room)}
            showSharedAllotments={showSharedAllotments}
            disabled={date.isBefore(moment(), 'day')}
          />
        ))}
      </div>
    </div>
  );
}
