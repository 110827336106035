import styled from 'styled-components';
import { Button, Modal } from 'components';
import { IcArchive } from 'components/icons';
import { Stack } from '@koob/margaret';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnack, useError } from 'hooks';
import { useTranslation, Trans } from 'react-i18next';
import { useAsync } from "react-async";
import { getApiKey } from "./ApiKeyEdit";
import { destroy } from "../../../api/node";

const Warning = styled.div`
  color: ${({ theme }) => theme.danger};
  font-weight: bold;
`;

export const deleteApiKey = async ({ id }) => {
  return destroy(`/organization-api-keys/${id}`);
};

export default function ApiKeyArchive() {
  const { apiKeyId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('organizationApiKeys');
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();

  const { data } = useAsync({
    promiseFn: getApiKey,
    id: apiKeyId,
  });
  const apiKey = data?.data;

  const handleArchive = async () => {
    try {
      await deleteApiKey({ id: apiKeyId });

      navigate(-1);
      notify(t('discardSuccess'));
    } catch (err) {
      sendErrorSnack(err);
    }
  };

  return (
    <Modal
      isOpen
      title={t('archive.title', {
        name: apiKey?.name,
      })}
      onRequestClose={() => navigate(-1)}
      footer={
        <Stack alignX="flex-end" gutterSize={1}>
          <Button
            onClick={handleArchive}
            variant="danger"
            icon={<IcArchive size={20} />}
          >
            {t('archive.confirm')}
          </Button>

          <Button onClick={() => navigate(-1)} variant="simple">
            {t('misc:cancel')}
          </Button>
        </Stack>
      }
    >
      <Stack direction="column" gutterSize={1} alignX="center">
        <Warning>{t('archive.heading')}</Warning>
        <div>
          <Trans
            i18nKey="organizationApiKeys:archive.explanation"
            name={apiKey?.name}
          >
            <strong>{{ name: apiKey?.name }}</strong>
          </Trans>
        </div>
      </Stack>
    </Modal>
  );
}
