import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { media, Stack } from '@koob/margaret';
import { CompositionBed } from 'components/BookingTunnel/SelectRoomsForm';
import { IoMdBed } from 'react-icons/io';
import { format, parseISO } from 'date-fns';
import Tooltip from 'components/Tooltip';

const RoomExtraContent = styled(Stack)`
  padding-left: 0;
  ${props =>
    props.marginTop &&
    css`
      margin-top: 30px;
    `}

  padding-right: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding-left: ${({ theme }) => theme.spacing()};
  `}
  p {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  }
`;
export const BedTag = styled(Stack).attrs({
  gutterSize: 0.5,
  alignY: 'center',
})`
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.separator};
  margin-left: 5px;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.75)};
  ${props =>
    props.selected &&
    css`
      background: ${({ theme }) => theme.orangeLight2};
      color: ${({ theme }) => theme.orange};
    `}
  ${props =>
    props.red &&
    css`
      background-color: #ffe3e3;
      color: #ea5d5d !important;
    `}
  ${props =>
    props.grey &&
    css`
      color: rgba(0, 0, 0, 0.5);
      background-color: #eeeeee;
    `}
  ${props =>
    props.green &&
    css`
      background: rgba(115, 201, 146, 0.12);
      color: #73c992;
    `}

  border-radius: 30px;
`;

const ExtraBedTags = styled(Stack)`
  max-width: 400px;
`;

const TagStack = styled(Stack)`
  max-width: 400px;
`;

function RoomTags({ room, bedIndex, marginTop, isFilteredSelectBed }) {
  const { t } = useTranslation('bookings');
  let bedChoices = null; // Déclaration de bedChoices au début
  const beds = room.bedChoices;
  if (
    (Array.isArray(room.bedChoices) &&
      room?.bedChoices[bedIndex]?.tags?.length <= 0) ||
    (typeof room.bedChoice === 'object' &&
      room?.bedChoice?.tags?.length <= 0) ||
    (room.tags !== undefined && room?.tags?.length <= 0)
  ) {
    return null;
  }
  if (Array.isArray(room.tags)) {
    bedChoices = room;
  } else {
    bedChoices = Array.isArray(room.bedChoices)
      ? room.bedChoices[bedIndex]
      : room.bedChoice;
  }
  if (null === bedChoices) {
    return null;
  }

  return (
    <RoomExtraContent
      className="w-full"
      direction="column"
      gutterSize={0.5}
      marginTop={marginTop}
    >
      <Stack
        className="w-full whitespace-nowrap"
        alignX="space-between"
        style={{
          flexWrap: 'wrap',
          gap: '0.5rem',
        }}
      >
        <TagStack
          justifyContent="flex-start"
          style={{
            flexWrap: 'wrap',
          }}
        >
          {(bedChoices?.tags || []).map((name, index) => {
            const startAt = name?.startAt ?? name?.start_at;
            const endAt = name?.endAt ?? name?.end_at;
            return (
              <>
                {name?.label && (
                  <Tooltip
                    tip={
                      startAt && endAt
                        ? `${format(parseISO(startAt), 'dd/MM/yyyy')}
                - ${format(parseISO(endAt), 'dd/MM/yyyy')}`
                        : ''
                    }
                    disabled={!startAt || !endAt}
                    color="purple"
                    hasArrow={false}
                    position="left"
                  >
                    <BedTag
                      key={index}
                      red={name?.label === 'room_non_refundable'}
                      green={name?.label === 'room_refundable'}
                      grey={
                        name?.label !== 'room_refundable' &&
                        name?.label !== 'room_non_refundable'
                      }
                    >
                      {name?.label?.indexOf('_') !== -1 ||
                      name?.label?.indexOf('.') !== -1
                        ? t(name?.label)
                        : name?.label}
                    </BedTag>
                  </Tooltip>
                )}
              </>
            );
          })}
        </TagStack>
        <ExtraBedTags
          gutterSize={0.5}
          alignX="flex-end"
          style={{
            flexWrap: 'wrap',
            marginLeft: 'auto',
          }}
        >
          {(beds || [])
            .filter((elem, index) => {
              const isBedExist = elem.name && elem.name.length > 0;
              if (!isFilteredSelectBed) {
                return isBedExist;
              } else {
                return isBedExist && index === bedIndex;
              }
            })
            .map((bed, index) => (
              <CompositionBed key={index}>
                <div>
                  <IoMdBed size={18} />
                </div>
                <div>{bed?.name}</div>
              </CompositionBed>
            ))}
        </ExtraBedTags>
      </Stack>
    </RoomExtraContent>
  );
}

export default RoomTags;
