import { useTranslation } from 'react-i18next';
import { Stack } from '@koob/margaret';
import { Container } from 'ui';
import { EXPERIENCE_TRANSFER_CONFIG_SCHEMA } from '../../Utilities/ExperienceValidationSchemas';
import TextFieldButton from '../../../../../components/Fields/TextFieldButton';
import TypologyButton from '../../Partials/TypologyButton';
import React from 'react';
import ExperienceTransferTypeIcon from '../../Partials/ExperienceTransferTypeIcon';
import { useField } from 'formik';
import { SegmentedControlField } from '../../../../../components/Fields';

export function ExperienceTransferConfig() {
  const { t } = useTranslation('experiences');
  const [transfer] = useField('transfer');
  const typeField = useField('transfer.type');
  return (
    <>
      <Container size="full">
        <Stack direction="column" gutterSize={1.5} size="full">
          <div className="my-8 w-full flex justify-center space-x-5">
            {[
              { label: t('form.transfer.types.land'), value: 'LAND' },
              { label: t('form.transfer.types.air'), value: 'AIR' },
              { label: t('form.transfer.types.rail'), value: 'RAIL' },
              { label: t('form.transfer.types.boat'), value: 'BOAT' },
            ].map((option, index) => (
              <TypologyButton
                key={index}
                selected={transfer?.value?.type === option.value}
                onSelect={() => typeField[2].setValue(option.value)}
                icon={
                  <ExperienceTransferTypeIcon
                    type={option.value}
                    sizeClass="h-8 w-8"
                  />
                }
                name={t('form.transfer.types.' + option.value.toLowerCase())}
                small
              />
            ))}
          </div>

          <div className="w-full flex justify-around">
            <div className="flex space-x-3">
              <TextFieldButton
                type="number"
                step="1"
                label={t('form.transfer.departureTime')}
                name="transfer.departureTimeHour"
                content={'H'}
                min="0"
                max="23"
              />
              <TextFieldButton
                type="number"
                step="15"
                name="transfer.departureTimeMin"
                content={'min'}
                min="0"
                max="59"
              />
            </div>

            <div className="flex space-x-3">
              <TextFieldButton
                type="number"
                step="1"
                label={t('form.transfer.arrivalTime')}
                name="transfer.arrivalTimeHour"
                content={'H'}
                min="0"
                max="23"
              />
              <TextFieldButton
                type="number"
                step="15"
                name="transfer.arrivalTimeMin"
                content={'min'}
                min="0"
                max="59"
              />
            </div>

            <Stack direction="column" gutterSize={0.5}>
              <SegmentedControlField
                name="transfer.time"
                options={[
                  { label: t('form.timeOptions.day'), value: 'day' },
                  { label: t('form.timeOptions.night'), value: 'night' },
                ]}
                label={t('form.time')}
              />
            </Stack>
          </div>
        </Stack>
      </Container>
    </>
  );
}

export const getSchema = () => EXPERIENCE_TRANSFER_CONFIG_SCHEMA;
