import { ExtraScopeEnum } from "@koob/enums";

export const getExtraTitle = ({extra, extrasGroup, translation}) => {
    const groupKey = `${extra.scope}-${extra.dayIndex}`;
  
    if (!extrasGroup.has(groupKey)) {
      extrasGroup.add(groupKey);
      return (
        <div className="text-sm uppercase font-semibold text-gray-500">
          {`${translation('extras.title')} - ${
            extra.scope === ExtraScopeEnum.DAY
              ? `${translation('extras.day')} ${extra.dayIndex + 1}`
              : ExtraScopeEnum.GLOBAL
          }`}
        </div>
      );
    }
    return null;
};

export const sortExtras = (extras) => {
  return extras.sort((a, b) => {
    if (a.scope === ExtraScopeEnum.GLOBAL && b.scope !== ExtraScopeEnum.GLOBAL) return -1;
    if (a.scope !== ExtraScopeEnum.GLOBAL && b.scope === ExtraScopeEnum.GLOBAL) return 1;

    if (a.dayIndex !== b.dayIndex) {
      return a.dayIndex - b.dayIndex;
    }

    if (a?.isIncluded && !b?.isIncluded) return -1;
    if (!a?.isIncluded && b?.isIncluded) return 1;

    return 0; 
  });
};

  