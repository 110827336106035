import { Marker } from '@react-google-maps/api';

const ExperiencePointItineraryMarker = ({
  label, 
  experience, 
  icon,
  zoom,
  clusterer,
  children
}) => {
  const position = {
    lat: parseFloat(experience?.lat),
    lng: parseFloat(experience?.lon)
  }

  return (
    <>
      <Marker
        position={position}
        label={label}
        icon={icon}
        clusterer={clusterer}
      >
        {zoom > 9 && children}
      </Marker>
    </>
  );
}

export default ExperiencePointItineraryMarker;