import React from 'react';

export default function KoobButton({
  color = 'primary',
  block = false,
  loading,
  onClick,
  disabled,
  children,
  size = 'md',
  type = 'button',
  cypressId,
  ...props
}) {
  const sizeClass = () => {
    switch (size) {
      case 'sm':
        return 'px-2 py-1 text-sm';
      case 'md':
        return 'px-4 py-2 text-base';
      default:
    }
  };

  return (
    <button
      className={[
        sizeClass(),
        'rounded-md font-medium focus:outline-none shadow-k transition',
        color === 'primary' &&
          'bg-gradient-to-b from-k-gd1 to-k-gd2 text-white',
        color === 'secondary'
          ? 'bg-white hover:bg-white border border-gray-200 hover:border-gray-300 text-gray-600'
          : 'border-none',
        color === 'green' &&
          'bg-gradient-to-b from-green-500/75 to-green-500 text-white',
        color === 'light' && 'text-gray-500 hover:text-gray-600',
        loading || disabled
          ? 'opacity-50 cursor-not-allowed'
          : 'hover:bg-gray-100',
        block ? 'w-full' : '',
      ].join(' ')}
      onClick={!disabled && !loading ? onClick : () => {}}
      type={type}
      disabled={disabled || loading}
      data-cy={cypressId}
      {...props}
    >
      {children}
    </button>
  );
}
