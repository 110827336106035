import { Swiper} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useRef } from "react";

const ExperienceSliderCard = ({children}) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="w-full max-w-lg mx-auto overflow-hidden" style={{paddingLeft: 30, paddingRight: 30, marginLeft: 5}}>
      <Swiper
        modules={[Navigation]}
        spaceBetween={10}
        slidesPerView={1}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        className="relative rounded-lg shadow-md h-auto"
      >
        <div
          ref={prevRef}
          className="fixed -left-0 top-1/2 -translate-y-1/2 w-10 h-10 flex items-center justify-center rounded-full bg-gray-600/80 text-white hover:bg-gray-700 transition-colors z-10"
        >
          <i className="far fa-chevron-left"></i>
        </div>
        
        <div
          ref={nextRef}
          className="fixed -right-0 top-1/2 -translate-y-1/2 w-10 h-10 flex items-center justify-center rounded-full bg-gray-600/80 text-white hover:bg-gray-700 transition-colors z-10"
        >
         <i className="far fa-chevron-right"></i>
        </div>
       
        {children}
      </Swiper>
    </div>
  )
}

export default ExperienceSliderCard;