const programDayMarker = (mainColor, fillColor) => {
  const svgContent = `
  <svg width="45px" height="60px" viewBox="0 0 36 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icons / Maps / Place Hotel Active</title>
    <defs>
        <path d="M18,4 C10.26,4 4,10.26 4,18 C4,28.5 18,44 18,44 C18,44 32,28.5 32,18 C32,10.26 25.74,4 18,4 Z" id="path-1"></path>
        <filter x="-30.4%" y="-16.2%" width="160.7%" height="143.7%" filterUnits="objectBoundingBox" id="filter-2">
            <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <filter color-interpolation-filters="auto" id="filter-3">
            <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"></feColorMatrix>
        </filter>
    </defs>
    <g id="Icons-/-Maps-/-Place-Hotel-Active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Path">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
            <use fill="${mainColor}" fill-rule="evenodd" xlink:href="#path-1"></use>
        </g>
        <g transform="matrix(1 0 0 1 18 24)" id="54b94ae6-e59b-47a2-9a4c-cf0c694dce85"  >
        <rect style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;" vector-effect="non-scaling-stroke"  x="-18" y="-24" rx="0" ry="0" width="36" height="48" />
        </g>
        <g transform="matrix(0 0 0 0 0 0)" id="4bfdbf72-fb7f-4847-bffe-c3e48bf811a0"  >
        </g>
        <g transform="matrix(0 0 0 0 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(0.03 0 0 0.03 18 13)"  >
        <path fill="${fillColor}" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill-rule: nonzero; opacity: 1;"  transform=" translate(-224, -256)" d="M 112 0 C 120.8 0 128 7.2 128 16 L 128 64 L 320 64 L 320 16 C 320 7.199999999999999 327.2 0 336 0 C 344.8 0 352 7.2 352 16 L 352 64 L 384 64 C 419.3 64 448 92.7 448 128 L 448 160 L 448 192 L 448 448 C 448 483.3 419.3 512 384 512 L 64 512 C 28.700000000000003 512 0 483.3 0 448 L 0 192 L 0 160 L 0 128 C 0 92.7 28.7 64 64 64 L 96 64 L 96 16 C 96 7.199999999999999 103.2 0 112 0 z M 416 192 L 32 192 L 32 448 C 32 465.7 46.3 480 64 480 L 384 480 C 401.7 480 416 465.7 416 448 L 416 192 z M 384 96 L 64 96 C 46.3 96 32 110.3 32 128 L 32 160 L 416 160 L 416 128 C 416 110.3 401.7 96 384 96 z M 230 265.4 C 242.5 247.29999999999998 269.2 246.99999999999997 282.2 264.79999999999995 L 378.5 397.19999999999993 C 393.9 418.3999999999999 378.8 447.99999999999994 352.6 447.99999999999994 L 95.1 447.99999999999994 C 69.1 447.99999999999994 53.89999999999999 418.59999999999997 69.1 397.3999999999999 L 120.1 326.0999999999999 C 131.6 309.9999999999999 154.8 308.0999999999999 168.8 322.0999999999999 L 181.9 335.19999999999993 L 230 265.4 z M 352.6 416 L 256.3 283.6 L 197.10000000000002 369.1 C 194.40000000000003 373 190.10000000000002 375.5 185.40000000000003 375.90000000000003 C 180.70000000000005 376.30000000000007 176.00000000000003 374.6 172.60000000000002 371.3 L 146 344.7 L 95.1 416 L 352.6 416 z M 96 232 C 109.25483399593904 232 120 242.74516600406096 120 256 C 120 269.25483399593907 109.25483399593905 280 96.00000000000001 280 C 82.74516600406096 280 72 269.25483399593907 72 256 C 72 242.74516600406096 82.74516600406096 232 96 232 z" stroke-linecap="round" />
        </g>
        <g transform="matrix(0 0 0 0 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(0 0 0 0 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(0 0 0 0 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(0 0 0 0 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(0 0 0 0 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(0 0 0 0 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(0 0 0 0 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(0 0 0 0 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
        <g style=""   >
        </g>
        </g>
        <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
        <g style=""   >
        </g>
        </g>
    </g>
  </svg>`;
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
}

export default programDayMarker;