import { Stack } from '@koob/margaret';
import { useField } from 'formik';
import React, { useEffect } from 'react';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { Container } from 'ui';
import { post } from '../../../../api/node';
import { MultiSearchableSelectField } from '../../../../components/Fields';
import LocalMultiSearchableSelectField from '../../../../components/Fields/LocalMultiSearchableSelectField';
import { IcBestMatch } from '../../../../components/icons';
import { GET_EXPERIENCES } from '../Partials/DayForm';
import { EXPERIENCE_OPTIONS_SCHEMA } from '../Utilities/ExperienceValidationSchemas';

const getExperiencesList = async ({ startAt, endAt, except }) => {
  return await post('/experiences/liste', {
    startAt,
    endAt,
    except,
  });
};

function FilteredOptions(optionList) {
  const [{ value: programs }, ,] = useField(`programs`);
  const combinedIncludedAndSuggestedIds = programs?.flatMap(program => {
    const suggestedIds =
      program.suggestedExperiences?.map(option => option.id) || [];
    const includedIds =
      program.includedExperiences?.map(option => option.id) || [];
    return [...suggestedIds, ...includedIds];
  });

  return optionList?.filter(
    option => !combinedIncludedAndSuggestedIds?.includes(option?.id),
  );
}

function ExperienceOptionsPerPeriod({ periodIndex, currentLocale }) {
  const { t } = useTranslation('experiences');

  const [{ value: experienceId }] = useField(`id`);
  const [{ value: extras }, , { setValue: setExtras }] = useField(`extras`);
  const [{ value: period }, , { setValue: setPeriod }] = useField(
    `periods[${periodIndex}]`,
  );

  const { data, reload } = useAsync({
    promiseFn: getExperiencesList,
    startAt: period.startAt,
    endAt: period.endAt,
    except: experienceId,
    locale: currentLocale.value,
  });

  useEffect(() => {
    if (data) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocale?.value]);

  const filteredOptions = FilteredOptions(data?.data);

  const options = (filteredOptions ?? []).map(experience => {
    return {
      label: experience.name,
      value: experience.id,
    };
  });

  return (
    <Stack
      direction="column"
      gutterSize={1.5}
      size="full"
      className="p-4 border rounded-lg"
    >
      <span style={{ flex: '0 0 auto', marginBottom: '5px' }}>
        {t('steps.options.period', {
          startAt: period.startAt,
          endAt: period.endAt,
        })}
      </span>

      <LocalMultiSearchableSelectField
        label={''}
        name={`periods[${periodIndex}].extras`}
        options={options}
        renderOption={option => option.label}
        renderSelectedOption={option => option.label}
        onSelectOption={option => {
          if (extras.findIndex(extra => extra.id === option.value) === -1) {
            setExtras(
              [
                ...extras,
                {
                  id: option.value,
                  name: option.label,
                  suggested: [],
                  required: [],
                },
              ],
              true,
            );
          }
          setPeriod({
            ...period,
            extras: [],
          });
        }}
      />
    </Stack>
  );
}

function ExtrasList(locale) {
  const { t } = useTranslation('experiences');
  const [{ value }, , { setValue }] = useField(`extras`);
  const filteredOptions = FilteredOptions(value);

  return (
    <Stack direction="column" style={{ flex: '1' }} size={'full'}>
      <div className="w-full border rounded-lg">
        <div className="px-4 py-3 border-b font-semibold">
          {t('steps.options.optionsName')}
        </div>

        <div className="flex-col divide-y">
          {filteredOptions?.map(extra => {
            const index = value.findIndex(elem => elem.id === extra.id);

            return (
              <div
                key={extra?.id}
                className="p-4 flex justify-between space-x-3 items-start"
              >
                <details className="group py-1 text-sm w-full">
                  <summary className="flex cursor-pointer flex-row items-center justify-between w-full font-semibold text-gray-800 marker:[font-size:0px]">
                    <div className="font-medium text-base">{extra.name}</div>

                    <svg
                      className="h-6 w-6 rotate-0 transform text-gray-900 group-open:rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </summary>

                  <div className="mt-3 pl-4 flex flex-col gap-4">
                    <MultiSearchableSelectField
                      label={t('steps.options.requiredOptions')}
                      name={`extras[${index}].required`}
                      query={GET_EXPERIENCES}
                      variables={{
                        types: ['Activity', 'Transfer'],
                        locale: locale.value,
                      }}
                      zIndex={1000}
                      pathToEdges={['experiences', 'edges']}
                      renderOption={option => option.name}
                      renderSelectedOption={option => option.name}
                      includedIconList={{
                        includedIcon: <IcBestMatch />,
                        excludedIcon: '',
                      }}
                      useFieldNameToFilter={'extras.required'}
                    />

                    <MultiSearchableSelectField
                      label={t('steps.options.suggestedOptions')}
                      name={`extras[${index}].suggested`}
                      query={GET_EXPERIENCES}
                      variables={{
                        types: ['Activity', 'Transfer'],
                        locale: locale.value,
                      }}
                      pathToEdges={['experiences', 'edges']}
                      renderOption={option => option.name}
                      renderSelectedOption={option => option.name}
                      includedIconList={{
                        includedIcon: <IcBestMatch />,
                        excludedIcon: '',
                      }}
                      useFieldNameToFilter={'extras.suggested'}
                    />
                  </div>
                </details>

                <div className="mt-1.5">
                  <MdClose
                    size={20}
                    className="cursor-pointer"
                    onClick={() =>
                      setValue(value.filter(elem => elem.id !== extra.id))
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Stack>
  );
}

export function ExperienceOptions({ currentLocale }) {
  const [{ value: periods }] = useField(`periods`);
  return (
    <Container size="full">
      <Stack direction="column" gutterSize={1.5} size="full">
        {periods.map((period, index) => (
          <ExperienceOptionsPerPeriod
            key={index}
            periodIndex={index}
            currentLocale={currentLocale}
          />
        ))}

        <ExtrasList locale={currentLocale}></ExtrasList>
      </Stack>
    </Container>
  );
}

export const getSchema = () => EXPERIENCE_OPTIONS_SCHEMA;
