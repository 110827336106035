import { KoobStateEnum } from '@koob/to/src/utils';
import { EASIA_TRAVEL_ORG_IDS } from '../constants';

export function mergeOrganizationFromToRequestConnectionList(toConnectionRequestData) {
  if (!toConnectionRequestData?.data) return [];

  const formattedToRequests = Object.values(toConnectionRequestData.data)
    .reduce((acc, curr) => {
      if (curr.state === KoobStateEnum.CONFIRMED) {
        const { id } = curr.toorganization;
        const type = curr.type;

        if (!acc[id]) {
          acc[id] = { ...curr, [type]: curr };
        } else {
          acc[id][type] = curr;
        }
      }
    return acc;
  }, {});

  return Object.values(formattedToRequests);
}

export function isEasiaOrga(orgId) {
  return (
    EASIA_TRAVEL_ORG_IDS.split(',').map(Number).includes(parseInt(orgId))
  )
}
