import { getLanguages } from '../../../../utils/language';
import { readFile } from '../../../../utils';
import moment from "moment";

function formatApiTime(time) {
  if (time) {
    const timeArray = time.split(':');

    return {
      hour: timeArray[0],
      min: timeArray[1],
    };
  }
  return null;
}

function getTimeCombined(hours, minutes) {
  if (!hours) {
    return;
  }
  let completeTime = hours + ':';
  if (!minutes) {
    completeTime += '00';
  } else {
    completeTime += ('' + minutes).padStart(2, '0');
  }
  return completeTime;
}

export function formatExperienceApiData(experienceBdd, deleteIds = false) {
  const periods = experienceBdd?.periods ?? [];
  const dayLabels = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  const formattedPeriods = [];
  periods?.sort((a, b) =>
    moment(a.startAt).isBefore(moment(b.startAt)) ? -1 : 1,
  ).forEach(item => {
    const daysObj = !item.availableDays
      ? []
      : item.availableDays.reduce((obj, day, index) => {
          obj[dayLabels[index]] = day === 1;
          return obj;
        }, {});
    const formattedPeriod = {
      ...item,
      days: JSON.parse(JSON.stringify(daysObj)),
    };
    if (deleteIds) {
      delete formattedPeriod.id;
    }
    formattedPeriods.push(formattedPeriod);
  });

  const languages = getLanguages();

  const obj = {
    ...experienceBdd,
    bestMatches:
      experienceBdd?.bestMatches?.map(match => {
        return {
          label: match?.name,
          value: match?.id,
        };
      }) ?? undefined,
    incompatibilities:
      experienceBdd?.incompatibilities?.map(match => {
        return {
          label: match?.name,
          value: match?.id,
        };
      }) ?? undefined,
    koobReference: experienceBdd.id,
    inCountry: experienceBdd?.inCity?.country,
    periods: formattedPeriods,
    mediaLinks: experienceBdd?.mediaLinks ?? [],
    pictures: experienceBdd?.pictures ?? [],
    guidingLanguages: experienceBdd?.guidingLanguages?.map(l => {
      return {
        value: l,
        label: languages.find(lang => lang.value === l)?.label,
      };
    }),
    activity: {
      ...experienceBdd?.activity,
      pickUpPointTimeHour: formatApiTime(
        experienceBdd?.activity?.pickUpPointTime,
      )?.hour,
      pickUpPointTimeMin: formatApiTime(
        experienceBdd?.activity?.pickUpPointTime,
      )?.min,
      dropOffPointTimeHour: formatApiTime(
        experienceBdd?.activity?.dropOffPointTime,
      )?.hour,
      dropOffPointTimeMin: formatApiTime(
        experienceBdd?.activity?.dropOffPointTime,
      )?.min,
    },
    transfer: {
      ...experienceBdd?.transfer,
      departureTimeHour: formatApiTime(experienceBdd?.transfer?.departureTime)
        ?.hour,
      departureTimeMin: formatApiTime(experienceBdd?.transfer?.departureTime)
        ?.min,
      arrivalTimeHour: formatApiTime(experienceBdd?.transfer?.arrivalTime)
        ?.hour,
      arrivalTimeMin: formatApiTime(experienceBdd?.transfer?.arrivalTime)?.min,
    },
    programs: (experienceBdd?.programs ?? []).map(program => {
      return {
        ...program,
        startingTimeHour: formatApiTime(program?.startingTime)?.hour,
        startingTimeMin: formatApiTime(program?.startingTime)?.min,
        endingTimeHour: formatApiTime(program?.endingTime)?.hour,
        endingTimeMin: formatApiTime(program?.endingTime)?.min,
        meals: (program?.meals ?? []).map(meal => {
          return {
            label: meal.charAt(0).toUpperCase() + meal.slice(1),
            value: meal,
          };
        }),
      };
    }),
  };

  if (deleteIds) {
    delete obj.id;
    delete obj?.transfer?.id;
    delete obj?.activity?.id;
  }

  return obj;
}

export async function formatExperienceFormPayload(experience) {
  const formattedPeriods = [];
  const formatPrograms = async programs => {
    return await Promise.all(
      programs.map(async program => {
        return {
          ...program,
          programExperiences: program?.programExperiences?.map(programExp => {
            return {
              id: programExp?.id.toString(),
            };
          }),
          startingTime: getTimeCombined(
            program?.startingTimeHour,
            program?.startingTimeMin,
          ),
          endingTime: getTimeCombined(
            program?.endingTimeHour,
            program?.endingTimeMin,
          ),
          guiding: experience?.guiding ?? program?.guiding,
          guidingLanguages: experience?.guidingLanguages?.reduce(
            (acc, l) => [...acc, l.value],
            [],
          ),
          meals: program?.meals?.map(meal => meal.value ?? meal),
          accomodationExperience:
            program.accomodationType === 'nightTransfer'
              ? program.accomodationExperience
              : undefined,
          accomodationHotel:
            program.accomodationType === 'hotel'
              ? program.accomodationHotel
              : undefined,
          alternativeHotels:
            program.accomodationType === 'hotel'
              ? program.alternativeHotels
              : undefined,
          picture: program.picture
            ? {
                imageUrl:
                  program.picture && !program.picture.id
                    ? await readFile(program.picture)
                    : program.picture.imageUrl,
                displayName:
                  program.picture.name ?? program.picture.displayName,
                isMainPicture: false,
                id: program?.picture?.id,
              }
            : undefined,
        };
      }),
    );
  };

  if (experience?.periods) {
    experience?.periods.forEach(period => {
      const days = Object.values(period.days ?? period.availableDays).map(val =>
        val ? 1 : 0,
      );

      formattedPeriods.push({
        ...period,
        extras: undefined,
        availableDays: days,
      });
    });
  }

  const programs = await formatPrograms(experience?.programs ?? []);

  return {
    ...experience,
    isRepeatable: experience?.isRepeatable ?? false,
    bestMatches:
      experience?.bestMatches?.map(match => {
        return {
          label: match?.label,
          id: match?.value ?? match?.id,
        };
      }) ?? undefined,
    incompatibilities:
      experience?.incompatibilities?.map(match => {
        return {
          label: match?.label,
          id: match?.value ?? match?.id,
        };
      }) ?? undefined,
    distance:
      experience?.distance && experience?.distance !== ''
        ? experience.distance
        : undefined,
    periods: formattedPeriods,
    mainPicture: experience.mainPicture
      ? {
          displayName: experience.mainPicture.displayName,
          imageUrl: experience.mainPicture.imageUrl,
        }
      : undefined,
    pictures: experience.pictures.map(elem => {
      return {
        displayName: elem.displayName,
        imageUrl: elem.imageUrl,
        isMainPicture: elem.isMainPicture,
        id: elem.id,
      };
    }),
    guidingLanguages: experience?.guidingLanguages?.map(l => {
      return l.value ?? l;
    }),
    activity:
      experience.type !== 'Activity'
        ? undefined
        : {
            ...experience.activity,
            pickUpPointTime: getTimeCombined(
              experience?.activity?.pickUpPointTimeHour,
              experience?.activity?.pickUpPointTimeMin,
            ),
            dropOffPointTime: getTimeCombined(
              experience?.activity?.dropOffPointTimeHour,
              experience?.activity?.dropOffPointTimeMin,
            ),
            guiding: experience?.guiding ?? experience?.activity?.guiding,
            guidingLanguages: experience?.guidingLanguages?.reduce(
              (acc, l) => [...acc, l.value],
              [],
            ),
          },
    transfer:
      experience.type !== 'Transfer'
        ? undefined
        : {
            ...experience.transfer,
            boat:
              experience.transfer?.type !== 'BOAT'
                ? undefined
                : experience.transfer.boat,
            air:
              experience.transfer?.type !== 'AIR'
                ? undefined
                : experience.transfer.air,
            land:
              experience.transfer?.type !== 'LAND'
                ? undefined
                : experience.transfer.land,
            rail:
              experience.transfer?.type !== 'RAIL'
                ? undefined
                : experience.transfer.rail,
            departureTime: getTimeCombined(
              experience?.transfer?.departureTimeHour,
              experience?.transfer?.departureTimeMin,
            ),
            arrivalTime: getTimeCombined(
              experience?.transfer?.arrivalTimeHour,
              experience?.transfer?.arrivalTimeMin,
            ),
            guiding: experience?.guiding ?? experience?.transfer?.guiding,
            guidingLanguages: experience?.guidingLanguages?.reduce(
              (acc, l) => [...acc, l.value],
              [],
            ),
          },
    programs: programs,
  };
}

export const formatPromotionFormPayload = promotion => {
  const formattedAvailablePeriods = [];
  const promotionCopy = { ...promotion };
  if (promotionCopy?.periods) {
    promotionCopy?.periods.forEach(period => {
      const days = Object.values(period.days).reduce((acc, val, index) => {
        if (val) {
          acc.push(index + 1);
        }
        return acc;
      }, []);

      formattedAvailablePeriods.push({
        startAt: period.startAt,
        endAt: period.endAt,
        days: days,
        paxRanges: promotionCopy?.benefit?.promoPerRoom
          ? []
          : period?.paxRanges,
        ageRanges: promotionCopy?.benefit?.promoPerRoom
          ? []
          : period?.ageRanges,
      });
    });
  }

  delete promotionCopy?.params;
  delete promotionCopy?.periods;

  const obj = {
    ...promotionCopy,
    linkedPromotions: promotionCopy?.cumulativePromotions,
    availablePeriods: formattedAvailablePeriods,
    benefit: {
      ...promotionCopy?.benefit,
      kind: promotionCopy?.benefit?.kind?.value,
      value: Number(promotionCopy?.benefit?.value),
    },
  };

  return obj;
};

export const formatPromotionApiData = promotion => {
  return {
    ...promotion,
    cumulativePromotions: promotion?.linkedPromotions,
    benefit: {
      ...promotion?.benefit,
    },
  };
};

export const arePromotionPeriodsValid = ({ promotion, experience }) => {
  const validExperiencePeriods = [];

  for (const promotionPeriod of promotion.periods ?? []) {
    const validExperiencePeriod = {
      ...experience,
      periods: experience?.periods?.filter(experiencePeriod => {
        const experienceStartAt = new Date(experiencePeriod?.startAt);
        const experienceEndAt = new Date(experiencePeriod?.endAt);
        const promotionStartAt = new Date(promotionPeriod?.startAt);
        const promotionEndAt = new Date(promotionPeriod?.endAt);

        const isValidPeriod =
          promotionStartAt >= experienceStartAt &&
          promotionEndAt <= experienceEndAt;

        return isValidPeriod;
      }),
    };

    if (validExperiencePeriod?.periods?.length > 0) {
      validExperiencePeriods.push(validExperiencePeriod);
    }
  }

  return validExperiencePeriods.length === promotion.periods.length;
};
