import React from 'react';

export default function KoobGroupedButtons({ value, onSelect, options, id, cypressId }) {
  return (
    <div
      id={id}
      data-cy={cypressId}
      className="self-start flex flex-row rounded items-start justify-start"
    >
      {options.map(({ value: optionValue, label }, index) => (
        <button
          key={`koob-grouped-buttons-button-${index}`}
          className={[
            'px-3 py-2 transition',
            value === optionValue
              ? 'bg-k-gd2 bg-opacity-30 hover:bg-opacity-20 border-k-gd2 text-k-gd2'
              : 'border-gray-300 text-gray-500 hover:text-gray-600',
            index === 0 ? 'rounded-l' : '',
            index === options.length - 1 ? 'rounded-r' : '',
            options?.[index + 1]?.value === value && 'border-r-0',
            options?.[index - 1]?.value === value && 'border-l-0',
            value !== optionValue && index < options.length - 1 && 'border-r-0'
          ].join(' ')}
          onClick={() => onSelect(optionValue)}
        >
          {label}
        </button>
      ))}
    </div>
  );
}
