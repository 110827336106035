import Promotion from "./Promotion";
import IcBasicDeal from "@koob/dmc/src/components/icons/IcBasicDeal";
import IcEarlyBooker from "@koob/dmc/src/components/icons/IcEarlyBooker";
import IcLastMinute from "@koob/dmc/src/components/icons/IcLastMinute";

const PROMOTION_ICONS = {
  basic_deal: <IcBasicDeal size={12} />,
  early_booker: <IcEarlyBooker size={12} />,
  last_minute: <IcLastMinute size={12} />
};

const PromotionTag = ({ name, kind, bgColor }) => {
  // Remove the amount from the promotion name
  const promotionName = name?.split(/[+-]/)[0].trim();

  return (
    <Promotion bgColor={bgColor}>
      {kind && <div>{PROMOTION_ICONS[kind]}</div>}
      <span>{promotionName}</span>
    </Promotion>
  );
};

export default PromotionTag;
