import styled, { css } from 'styled-components';
import { Stack } from '@koob/margaret';

const Promotion = styled(Stack).attrs({
    alignY: 'center',
    gutterSize: 0.25,
    marginBottom: 0.5,
})`
    font-size: 0.75rem;
    padding: ${({theme}) => theme.spacing(0.25)} ${({theme}) => theme.spacing(0.75)};
    border-radius: 40px;
    color: #2e1065;
    background-color: ${({bgColor}) => bgColor ?? '#e9d5ff'};

    ${props =>
        props.promo &&
        css`
            padding: ${({theme}) => theme.spacing(0.2)};
            margin-top: -1px !important;
        `
    }
`;

export default Promotion;