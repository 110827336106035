import { Stack } from "@koob/margaret";
import KoobButton from "components/Koob/KoobButton";
import KoobModal from "components/Koob/KoobModal";
import KoobParagraph from "components/Koob/KoobParagraph";
import KoobTitle from "components/Koob/KoobTitle";
import { useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";

const TextFieldButton = ({
  name,
  children,
  label,
  value,
  setValue,
  defaultValue,
}) => {
  const handleChange = (e) => {
    setValue(Number(e.target.value));
  };

  return (
    <div className="relative">
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="mt-1 relative">
        <input
          id={name}
          name={name}
          type="number"
          onChange={handleChange}
          value={value}
          defaultValue={defaultValue}
          className="block w-full h-10 pr-10 p-2 sm:text-sm border-gray-300 rounded-md"
          placeholder="Enter price"
        />

        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-gray-500 md:text-sm">{children}</span>
        </div>
      </div>
    </div>
  );
};

const ExperienceFiltersPriceRange = ({
  open,
  setOpen,
  onSave,
  priceRange,
  translation,
  currency,
}) => {
  const minPriceDefaultValue = 0;
  const maxPriceDefaultValue = 1000000000;

  const [minPrice, setMinPrice] = useState(priceRange?.[0]);
  const [maxPrice, setMaxPrice] = useState(priceRange?.[1]);
  const isDisabled = minPrice > maxPrice || minPrice < 0 || maxPrice < 0;

  return (
    <KoobModal
      open={open}
      setOpen={() => setOpen(false)}
      widthClass="sm:max-w-xl overflow-visible"
    >
      <div className="text-center max-w-lg mx-auto">
        <KoobTitle size="text-xl">{translation("priceRange.title")}</KoobTitle>
        <KoobParagraph size="text-sm">
          {translation("priceRange.description")}
        </KoobParagraph>
      </div>

      <Stack
        size="full"
        alignY="space-between"
        alignX="center"
        className="mt-8"
      >
        <Stack gutterSize={2} alignY="center">
          <Stack direction="column" gutterSize={2} className="w-40">
            <TextFieldButton
              value={minPrice}
              setValue={(min) => {
                setMinPrice(min);
              }}
              label={translation("priceRange.minPrice")}
              defaultValue={minPriceDefaultValue}
            >
              {getSymbolFromCurrency(currency)}
            </TextFieldButton>
          </Stack>

          <Stack direction="column" className="w-40">
            <TextFieldButton
              value={maxPrice}
              setValue={(max) => {
                setMaxPrice(max);
              }}
              label={translation("priceRange.maxPrice")}
              defaultValue={maxPriceDefaultValue}
            >
              {getSymbolFromCurrency(currency)}
            </TextFieldButton>
          </Stack>
        </Stack>
      </Stack>

      <div className="mt-8 flex justify-end space-x-2">
        <KoobButton
          onClick={() => {
            setOpen();
            onSave([minPrice, maxPrice]);
          }}
          color="primary"
          disabled={isDisabled}
        >
          {translation("misc:save")}
        </KoobButton>
      </div>
    </KoobModal>
  );
};

export default ExperienceFiltersPriceRange;
