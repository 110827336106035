import Tooltip from '@koob/to/src/components/Tooltip';
import PromotionTag from './PromotionTag';

const RequiredPromotionTag = ({name, label}) => {
    return (
      <Tooltip tip={label} position="top" hasArrow={false} color="purple">
        <PromotionTag bgColor="#3AEDCC" name={name}/>
      </Tooltip>
    );
};

export default RequiredPromotionTag;