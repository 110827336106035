import styled from "styled-components";
import KoobDropdownListDescription from "./KoobDropdownListDescription";
import { Stack } from "@koob/margaret";

const SubSectionTitleContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
`;

const LigneHorizontal = styled.hr`
  border: 0.00001em solid #d1d5db;
  width: 99%;
  opacity: 0.4;
  margin-top: 50px;
`;

export default function KoobAccordeonBloc({
  isLoading,
  accordeonDetail,
  variant,
}) {
  const details = (accordeonDetail || []).filter((elem) => !!elem.content);

  return (
    <>
      <div>
        {details?.length > 0 && (
          <>
            {variant !== "booking" && <LigneHorizontal />}

            <SubSectionTitleContainer>
              <Stack size="full" direction="column">
                {details.map((item, index) => (
                  <KoobDropdownListDescription
                    isLoading={isLoading}
                    key={index}
                    description={item}
                    variant={variant}
                  />
                ))}

                {variant !== "booking" && <LigneHorizontal />}
              </Stack>
            </SubSectionTitleContainer>
          </>
        )}
      </div>
    </>
  );
}
