const activityMarker = (mainColor, fillColor) => {
  const svgContent = `
  <svg width="45px" height="60px" viewBox="0 0 36 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icons / Maps / Place experience Active</title>
    <defs>
      <path d="M18 4C10.26 4 4 10.26 4 18c0 10.5 14 26 14 26s14 -15.5 14 -26c0 -7.74 -6.26 -14 -14 -14Z" id="path-1"/>
      <filter x="-30.4%" y="-16.2%" width="160.7%" height="143.7%" filterUnits="objectBoundingBox" id="filter-2">
        <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
        <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1"/>
      </filter>
      <filter color-interpolation-filters="auto" id="filter-3">
        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.F5700A 0"/>
      </filter>
    </defs>
    <g id="Icons-/-Maps-/-Place-experience-Active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Path">
        <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"/>
        <use fill="${mainColor}" fill-rule="evenodd" xlink:href="#path-1"/>
      </g>
      <g id="Filled-/-maps-/-place" transform="translate(8 9)" filter="url(#filter-3)">
        <g>
          <path fill="${fillColor}" d="M20.627 14.002a0.944 0.944 0 0 0 -0.496 -0.831 0.314 0.314 0 0 0 -0.296 0.551 0.313 0.313 0 0 1 0.169 0.281 0.311 0.311 0 0 1 -0.169 0.281l-9.087 4.895a0.938 0.938 0 0 1 -0.876 0.007L0.795 14.473a0.313 0.313 0 0 1 -0.173 -0.279 0.313 0.313 0 0 1 0.165 -0.284 0.313 0.313 0 0 0 -0.306 -0.545 0.945 0.945 0 0 0 0.025 1.665l9.075 4.713a1.559 1.559 0 0 0 1.461 -0.011l9.087 -4.895a0.944 0.944 0 0 0 0.496 -0.833z"/>
          <path fill="${fillColor}" d="m0.508 11.024 9.075 4.713a1.559 1.559 0 0 0 1.461 -0.011l9.087 -4.895a0.944 0.944 0 0 0 0.496 -0.833 0.944 0.944 0 0 0 -0.496 -0.831 0.314 0.314 0 0 0 -0.296 0.551 0.313 0.313 0 0 1 0.169 0.281 0.311 0.311 0 0 1 -0.169 0.281l-9.089 4.894a0.935 0.935 0 0 1 -0.876 0.007L0.795 10.468a0.314 0.314 0 0 1 -0.173 -0.279 0.314 0.314 0 0 1 0.165 -0.284 0.313 0.313 0 0 0 -0.306 -0.545 0.944 0.944 0 0 0 -0.484 0.84 0.943 0.943 0 0 0 0.51 0.824z"/>
          <path fill="${fillColor}" d="m0.508 7.019 9.075 4.713c0.221 0.115 0.47 0.175 0.72 0.175h0.001a1.575 1.575 0 0 0 0.741 -0.186l9.087 -4.895a0.944 0.944 0 0 0 0.496 -0.833 0.944 0.944 0 0 0 -0.496 -0.831L11.064 0.276a1.587 1.587 0 0 0 -1.503 0.012L0.481 5.354a0.944 0.944 0 0 0 -0.484 0.84 0.945 0.945 0 0 0 0.51 0.825zM0.787 5.9 9.866 0.834a0.935 0.935 0 0 1 0.901 -0.006l9.067 4.884a0.313 0.313 0 0 1 0.169 0.281 0.311 0.311 0 0 1 -0.169 0.281l-9.087 4.896a0.932 0.932 0 0 1 -0.444 0.112h-0.001a0.929 0.929 0 0 1 -0.431 -0.106L0.795 6.464a0.313 0.313 0 0 1 -0.173 -0.279 0.315 0.315 0 0 1 0.164 -0.284z"/>
        </g>
      </g>
    </g>
  </svg>`;

  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
}

export default activityMarker;