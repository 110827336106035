import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty, pickBy } from 'lodash';
import qs from 'qs';

const useSearchParams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let searchParams = {};

  const setSearchParams = params => {
    const toLocation =
      location.pathname +
      (!isEmpty(pickBy(params))
        ? `?${qs.stringify(pickBy(params), { arrayFormat: 'indices' })}`
        : '');
    navigate(toLocation, { replace: true });
  };

  try {
    searchParams = qs.parse(location.search.replace('?', ''));
  } catch (err) {
    console.error(err.message);
  }

  return [searchParams, setSearchParams];
};

export default useSearchParams;
