import { useState } from "react";
import { MdChevronRight } from "react-icons/md";
import styled from "styled-components";
import { Stack, ButtonReset, Spinner } from "@koob/margaret";

const OptionalInfoWrapper = styled(Stack).attrs({
  size: "full",
  direction: "column",
  alignX: "stretch",
})``;

const KoobDropdownListDescription = ({
  isLoading = false,
  description,
  customContent,
  variant,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const data = description;

  return (
    <>
      <div
        className="my-2 w-full rounded-lg shadow-k"
        style={
          variant === "booking"
            ? { background: "#eeeeee", boxShadow: "none" }
            : { background: "white" }
        }
        onMouseEnter={() => setIsExpanded(true)}
      >
        {data?.content !== "" && (
          <OptionalInfoWrapper>
            <ButtonReset
              type="button"
              onClick={() => setIsExpanded(!isExpanded)}
              style={{ width: "100%" }}
            >
              <div className="p-4 flex justify-between">
                <div className="flex space-x-1 items-center">
                  <div className="font-bold">
                    {data.description?.displayName}
                  </div>

                  <div>{data.description?.icon}</div>
                </div>
                <div
                  style={
                    isExpanded ? { transform: "rotate(90deg)" } : undefined
                  }
                >
                  <MdChevronRight size={20} />
                </div>
              </div>
            </ButtonReset>

            {isExpanded && (
              <>
                {isLoading ? (
                  <div className="w-full p-4 border-t content-center">
                    <Spinner />
                  </div>
                ) : (
                  <div className="p-4 border-t">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.content,
                      }}
                    />
                    {customContent && (
                      <div className="mt-4">{customContent}</div>
                    )}
                  </div>
                )}
              </>
            )}
          </OptionalInfoWrapper>
        )}
      </div>
    </>
  );
};

export default KoobDropdownListDescription;
