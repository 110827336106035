import { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import styled from 'styled-components';
import ExperienceCard from './ExperienceCard';
import { ExperienceTypeEnum } from '@koob/enums';
import generateMarker from '@koob/map-marker';

const Wrapper = styled.div`
  width: 250px;
  max-width: 100vw;
`;

const ExperienceMarker = ({
  experience,
  showInfoWindow = true,
  mode,
  onSelect,
  compatibility,
  setIsOpenSlide,
  typeOverride = null
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const color = {
        Activity: "#29CC87",
        AIR: "#9333EA",
        LAND: "#9333EA",
        RAIL: "#9333EA",
        BOAT: "#9333EA",
        Program: "#F5700A",
        AllExperienceTypes: "#FF204E",
    }

    const markerType = (type, isOpen) => {
        return generateMarker({
            type: type,
            fillColor: isOpen ? "#FFFF" : color[type],
            mainColor: isOpen ? color[type] : "#FFFF"
        })
    }

    return (
        <Marker
            position={{
                lat: parseFloat(experience?.inCity?.lat),
                lng: parseFloat(experience?.inCity?.lon),
            }}
            icon={
                markerType(typeOverride ?? experience.transferType ?? experience?.experienceType,
                typeOverride ? true : isOpen)
            }
            onClick={() => {
              if (typeOverride === ExperienceTypeEnum.ALL_EXPERIENCE_TYPES) {
                  setIsOpenSlide(true);
              } else {
                  setIsOpen(!isOpen);
              }
            }}
        >
            {isOpen && showInfoWindow && (
                <InfoWindow
                    options={{ maxWidth: 280, padding: 0 }}
                    position={{
                        lat: parseFloat(experience?.inCity?.lat),
                        lng: parseFloat(experience?.inCity?.lon),
                    }}
                    onCloseClick={() => setIsOpen(false)}
                >
                    <Wrapper>
                        <ExperienceCard
                            experience={experience}
                            mode={mode}
                            onSelect={onSelect}
                            compatibility={compatibility}
                            variant="infoWindow"
                            hasBorder={true}
                        />
                    </Wrapper>
                </InfoWindow>
            )}
        </Marker>
    );
};

export default ExperienceMarker;
