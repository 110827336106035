import styled, { css } from 'styled-components';

const ToggleSwitchWrapper = styled.div`
  display: block;
  width: 46px;
  height: 24px;
  position: relative;
  border-color: ${({ theme }) => theme.separatorLight};
  background: ${({ theme }) => theme.separatorLight};
  border-radius: 24px;
  transition: border-color 150ms ease-out, background-color 150ms ease-out;

  [type='checkbox'] {
    display: none;
  }

  ${({ checked }) =>
    checked &&
    css`
      background: ${({ theme }) => theme.success};
      border-color: ${({ theme }) => theme.success};
      box-shadow: none;
    `};
`;

const ToggleSwitchTrigger = styled.a`
  position: absolute;
  background-color: #ffffff;
  top: 2px;
  height: 20px;
  width: 20px;
  transition: left 150ms ease-out, background-color 150ms ease-out;
  border-radius: 100%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  border: 0;
  left: 2px;
  cursor: pointer;
  user-select: none;

  &:before {
    position: absolute;
    top: -6px;
    right: -3 * 24px;
    bottom: -6px;
    left: -3 * 24px;
    content: '';
    display: block;
  }

  ${({ checked }) =>
    checked &&
    css`
      left: calc(100% - 22px);
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const ToggleSwitchTrack = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #ffffff;
  cursor: pointer;
  user-select: none;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const ToggleSwitch = ({ checked, onChange, id, disabled, className, cypressId }) => (
  <ToggleSwitchWrapper checked={checked} disabled={disabled}>
    <ToggleSwitchTrack
      htmlFor={id}
      onClick={onChange}
      checked={checked}
      disabled={disabled}
      data-cy={`${cypressId}-${checked ? "enabled" : "disabled"}`}
    />
    <ToggleSwitchTrigger
      id={id}
      onClick={onChange}
      checked={checked}
      disabled={disabled}
      className={className}
    />
  </ToggleSwitchWrapper>
);

export default ToggleSwitch;
