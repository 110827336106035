import styled from 'styled-components';
import {
  Dropdown,
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
} from '@koob/margaret';
import Button from './Button';
import { AiOutlineCopy } from 'react-icons/ai';
import { ActionLink } from '../ui';

const MenuSetting = styled.div`
  text-align: center;
  margin-top: 5px;
`;

const DropdownElement = styled.div`
  position: relative;
  right: 90px;
`;

const TextLink = styled(ActionLink)`
  font-size: 14px;
  color: ${({ theme }) => theme.textLight};
  font-weight: 600;
  &:hover {
    color: orange;
  }
`;

const DropdownConnectionSettings = ({ links }) => {
  return (
    <>
      <MenuSetting>
        <Dropdown
          trigger={
            <Button style={{ position: 'relative' }}>
              <AiOutlineCopy size={20} color={'gray'} />
            </Button>
          }
        >
          <DropdownElement>
            <PopoverMenu>
              <PopoverItem>
                {links.map((link, index) => (
                  <PopoverItemButton
                    key={index}
                    style={{ borderTop: '1px solid rgba(0,0,0,0.06)' }}
                  >
                    <TextLink to={link.to}>{link.title}</TextLink>
                  </PopoverItemButton>
                ))}
              </PopoverItem>
            </PopoverMenu>
          </DropdownElement>
        </Dropdown>
      </MenuSetting>
    </>
  );
};

export default DropdownConnectionSettings;
