import { useState } from 'react';
import { gql, useQuery, NetworkStatus } from '@apollo/client';
import {
  DataTable,
  Button,
  Header,
  Breadcrumb,
  LocaleSwitcher,
} from 'components';
import { IcArchive, IcPencil } from 'components/icons';
import { ActionsLinks, ActionLink, Container } from 'ui';
import { MdAddCircleOutline } from 'react-icons/md';
import { IoSettingsOutline, IoWarningOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import {
  formatOrderCriteria,
  getActionsCellWidth,
  initialLocale,
  settingRequestState,
} from 'utils';
import { pickBy, isNil } from 'lodash';
import { useSearchParams, useApp } from 'hooks';
import {
  Stack,
  PopoverMenu,
  PopoverItem,
  PopoverItemButton,
} from '@koob/margaret';
import { useLocation } from 'react-router-dom';
import SettingRequestsByStateAndTheme from 'components/SettingRequest/SettingRequestsListByState';

export const GET_THEMES = gql`
  query getExperiencesThemes(
    $after: String
    $order: ExperienceThemeOrderCriteria
    $search: String
    $discarded: Boolean
  ) {
    experienceThemes(
      first: 15
      after: $after
      order: $order
      displayName: $search
      discarded: $discarded
    ) {
      edges {
        node {
          id
          displayName
          enDisplayName
        }
      }
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
    }
  }
`;

const ThemesList = () => {
  const { t } = useTranslation('experienceTheme');
  const { isAllowedTo, user } = useApp();

  const { state: selectedLocale } = useLocation();

  const [currentLocale, setCurrentLocale] = useState(
    selectedLocale ?? initialLocale,
  );

  const [{ search, state, ...searchParams }, setSearchParams] =
    useSearchParams();

  const { data, fetchMore, networkStatus } = useQuery(GET_THEMES, {
    variables: pickBy(
      {
        search,
        locale: currentLocale?.value,
        order: formatOrderCriteria(searchParams),
        discarded: state === 'archived' ?? false,
      },
      value => !isNil(value),
    ),
  });

  const isEmpty =
    networkStatus === NetworkStatus.ready &&
    (data?.experienceThemes?.edges || []).length === 0;

  const headings = [
    {
      slug: 'enDisplayName',
      label: (
        <Stack gutterSize={0.375} alignY="baseline">
          <span>{t('name')}</span>
          <span className={`flag-icon flag-icon-gb`} />
        </Stack>
      ),
      orderCriteria: 'enDisplayName',
    },
    {
      slug: 'displayName',
      label: (
        <Stack gutterSize={0.375} alignY="baseline">
          <span>{t('name')}</span>
          <span className={`flag-icon flag-icon-${currentLocale.alpha2}`} />
        </Stack>
      ),
      orderCriteria: 'displayName',
    },
    { slug: 'actions', cannotBeReordered: true, width: getActionsCellWidth(2) },
  ];

  const themesData = (data?.experienceThemes?.edges || []).map(({ node }) => ({
    enDisplayName: {
      value: node?.enDisplayName,
    },
    displayName: { value: node?.displayName },
    actions: {
      render: () =>
        isAllowedTo('manageExperienceSettings') &&
        state !== 'archived' && (
          <ActionsLinks>
            <ActionLink to={`${node?.id ?? ''}/edit`} state={currentLocale}>
              <IcPencil />
            </ActionLink>
            <ActionLink to={`${node?.id ?? ''}/archive`}>
              <IcArchive />
            </ActionLink>
          </ActionsLinks>
        ),
    },
  }));

  const handleLoadNextPage = () => {
    try {
      fetchMore({
        variables: {
          after: data?.experienceThemes?.pageInfo?.endCursor,
        },
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <>
      <Header
        rightElement={
          <>
            {isAllowedTo('manageExperienceSettings') && (
              <Button
                icon={<MdAddCircleOutline />}
                variant="primary"
                to="new"
                disabled={currentLocale.value !== initialLocale.value}
              >
                {t('newTheme')}
              </Button>
            )}

            {user?.role === 'dmc_admin' && (
              <Button
                icon={<MdAddCircleOutline />}
                variant="primary"
                to="../setting-request/new/theme"
              >
                {t('newSettingRequest')}
              </Button>
            )}
          </>
        }
        leftElement={
          <Breadcrumb
            popoverElement={
              <PopoverMenu>
                <PopoverItem>
                  <PopoverItemButton
                    type="button"
                    onClick={() => setSearchParams({ state: null })}
                  >
                    <Stack gutterSize={0} alignY="center">
                      <Stack>
                        <IoSettingsOutline />
                      </Stack>
                      <span>{t('misc:active')}</span>
                    </Stack>
                  </PopoverItemButton>
                  <PopoverItemButton
                    type="button"
                    onClick={() => setSearchParams({ state: 'archived' })}
                  >
                    <Stack alignY="center">
                      <Stack>
                        <IcArchive />
                      </Stack>
                      <span>{t('misc:archived')}</span>
                    </Stack>
                  </PopoverItemButton>
                  <PopoverItemButton
                    type="button"
                    onClick={() => setSearchParams({ state: 'pending' })}
                  >
                    <Stack alignY="center">
                      <Stack>
                        <IoWarningOutline color="orange" />
                      </Stack>
                      <span>{t('misc:pending')}</span>
                    </Stack>
                  </PopoverItemButton>
                  <PopoverItemButton
                    type="button"
                    onClick={() => setSearchParams({ state: 'refused' })}
                  >
                    <Stack alignY="center">
                      <Stack>
                        <IoWarningOutline color={'red'} />
                      </Stack>
                      <span>{t('misc:refused')}</span>
                    </Stack>
                  </PopoverItemButton>
                </PopoverItem>
              </PopoverMenu>
            }
          />
        }
      />
      <Container size="narrow" variant="main">
        {settingRequestState.includes(state) ? (
          <SettingRequestsByStateAndTheme
            settingName={'theme'}
            kind={'experience'}
            state={state}
          />
        ) : (
          <Stack direction="column" size="full" alignX="center" gutterSize={1}>
            <DataTable
              headings={headings}
              data={themesData}
              isEmpty={isEmpty}
              actions={
                <LocaleSwitcher
                  queryLocale={currentLocale}
                  updateQueryLocale={setCurrentLocale}
                />
              }
            />

            {data?.experienceThemes?.pageInfo?.hasNextPage && (
              <Button variant="simple" onClick={handleLoadNextPage}>
                {t('misc:seeMore')}
              </Button>
            )}
          </Stack>
        )}
      </Container>
    </>
  );
};

export default ThemesList;
