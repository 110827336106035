import { useTranslation } from 'react-i18next';
import BulkEditor from './BulkEditor';
import LocationAwareSearch from '../../../../components/LocationAwareSearch';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from '../../../../hooks';
import { useAsync } from 'react-async';
import { isEqual } from 'lodash';
import { post } from '../../../../api/node';
import { SegmentedControl } from '@koob/margaret';
import { Paginator } from 'components/Paginator';
import { OrderColumnEnum, OrderDirectionEnum } from '@koob/enums';
import { getImageUrl } from '@koob/utils';

const getList = async ({ filters }) => {
  return await post('/experiences/liste', filters);
};

export default function BulkEditorExperiences() {
  const { t } = useTranslation('toConnectionRequest');
  const [{ search }] = useSearchParams();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [type, setType] = useState([]);
  const [totalPages, setTotalPages] = useState();

  const requestFilters = {
    search: search ?? '',
    types: type && type != null ? [type] : [],
    perPage: perPage,
    page,
    direction: OrderDirectionEnum.DESC,
    order: OrderColumnEnum.UPDATED_AT
  };

  const { data, isLoading: loading } = useAsync({
    promiseFn: getList,
    filters: requestFilters,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters);
    },
    onResolve: ((response) => {
      setTotalPages(response?.headers?.xTotalPages)
    })
  });

  useEffect(() => {
    setPage(1);
  }, [search, type]);

  const experiences = data?.data ?? []
  const totalCount = data?.headers?.xTotalCount;

  return (
    <BulkEditor
      title={t('bulkEdit.editor.experiencesTitle')}
      filters={
        <div className="flex-col space-y-3">
          <LocationAwareSearch name="search" />

          <SegmentedControl
            options={[
              { label: t('experiences:state.all'), value: null },
              { label: t('experiences:transfer'), value: 'Transfer' },
              { label: t('experiences:types.activity'), value: 'Activity' },
              { label: t('experiences:types.extra'), value: 'Extra' },
              { label: t('experiences:types.program'), value: 'Program' },
            ]}
            value={type}
            onSelect={value => setType(value)}
          />
        </div>
      }
      loading={loading}
      results={
        experiences.map(experience => ({
          id: experience.id,
          image: getImageUrl(experience),
          name: experience.name,
          description: experience.experienceType,
        }))
      }
      type="experience"
      totalCount={totalCount}
      perPage={perPage}
      setPage={setPage}
      setTotalPages={setTotalPages}
      totalPages={data?.headers?.xTotalPages}
    >
      {experiences?.length > 0 && (
        <Paginator
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      )}
    </BulkEditor>
  );
}
