import { useNavigate } from 'react-router-dom';
import { Container, Card } from 'ui';
import { useSnack, useError } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Header } from 'components';
import ApiKeyForm from "./Partials/ApiKeyForm";
import { post } from "../../../api/node";

const createApiKey = async (values) => {
  return await post(`/organization-api-keys`, values);
};

export default function ApiKeyCreate() {
  const { t } = useTranslation('organizationApiKeys');
  const navigate = useNavigate();
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();

  const handleCreateApiKey = async (values) => {
    try {
      await createApiKey({
        ...values,
        organizationIds: values.organizationIds.map(({ value }) => value),
        apiKey: undefined
      });

      navigate(-1);
      notify(t('createSuccess'));
    } catch (err) {
      sendErrorSnack(err);
    }
  };

  return (
    <>
      <Header />

      <Container size="narrow">
        <Card>
          <ApiKeyForm
            onSubmit={handleCreateApiKey}
          />
        </Card>
      </Container>
    </>
  );
}
