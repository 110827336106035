import { 
  activityMarker, 
  airMarker, 
  allExperienceMarker, 
  boatMarker, 
  dayMarker, 
  flagMarker, 
  hotelMarker, 
  landMarker, 
  poiMarker, 
  programDayMarker, 
  programMarker, 
  railMarker, 
  startMarker 
} from './partials';

const generateMarker = ({ type, label, mainColor = '#FFFFFF', fillColor = '#F5700A' }) => {
  switch (type) {
    case 'day':
      return dayMarker(mainColor, fillColor, label);
    case 'flag':
      return flagMarker(mainColor, fillColor)
    case 'AIR':
      return airMarker(mainColor, fillColor)
    case 'LAND':
      return landMarker(mainColor, fillColor)
    case 'RAIL':
      return railMarker(mainColor, fillColor)
    case 'BOAT':
      return boatMarker(mainColor, fillColor)
    case 'Activity':
      return activityMarker(mainColor, fillColor)
    case 'start':
      return startMarker(mainColor, fillColor)
    case 'hotel':
      return hotelMarker(mainColor, fillColor)
    case 'poi':
        return poiMarker(mainColor, fillColor)
    case 'programDay':
      return programDayMarker(mainColor, fillColor)
    case 'AllExperienceTypes':
        return allExperienceMarker(mainColor, fillColor)
    case ('Program'):
      return programMarker(mainColor, fillColor)
    default:
      return null;
  }
};

export default generateMarker;