const landMarker = (mainColor, fillColor) => {
  const  svgContent = `
  <svg width="45px" height="60px" viewBox="0 0 36 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icons / Maps / Place experience Active</title>
    <defs>
      <path d="M18 4C10.26 4 4 10.26 4 18c0 10.5 14 26 14 26s14 -15.5 14 -26c0 -7.74 -6.26 -14 -14 -14Z" id="path-1"/>
      <filter x="-30.4%" y="-16.2%" width="160.7%" height="143.7%" filterUnits="objectBoundingBox" id="filter-2">
        <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
        <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1"/>
      </filter>
      <filter color-interpolation-filters="auto" id="filter-3">
        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.F5700A 0"/>
      </filter>
    </defs>
    <g id="Icons-/-Maps-/-Place-experience-Active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Path">
        <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"/>
        <use fill="${mainColor}" fill-rule="evenodd" xlink:href="#path-1"/>
      </g>
      <g id="Filled-/-maps-/-place" transform="translate(8 9)" filter="url(#filter-3)">
        <g>
        <path fill="${fillColor}" d="M4.156 3.461 2.98 7.578c0.25 -0.051 0.504 -0.078 0.77 -0.078h12.5c0.262 0 0.52 0.027 0.77 0.078L15.844 3.461c-0.27 -0.938 -1.125 -1.586 -2.102 -1.586H6.262c-0.977 0 -1.836 0.648 -2.102 1.586zM2.266 7.805 3.555 3.289C3.902 2.082 5.004 1.25 6.262 1.25h7.477c1.254 0 2.359 0.832 2.703 2.039l1.289 4.516c1.332 0.574 2.266 1.902 2.266 3.445v6.25c0 0.691 -0.559 1.25 -1.25 1.25H17.5c-0.691 0 -1.25 -0.559 -1.25 -1.25v-1.875H3.75v1.875c0 0.691 -0.559 1.25 -1.25 1.25H1.25c-0.691 0 -1.25 -0.559 -1.25 -1.25V11.25c0 -1.543 0.934 -2.871 2.266 -3.445zM16.25 15h3.125v-3.75c0 -1.727 -1.398 -3.125 -3.125 -3.125H3.75c-1.727 0 -3.125 1.398 -3.125 3.125v3.75h15.625zm0.625 0.625v1.875c0 0.344 0.281 0.625 0.625 0.625h1.25c0.344 0 0.625 -0.281 0.625 -0.625v-1.875h-2.5zm-16.25 0v1.875c0 0.344 0.281 0.625 0.625 0.625h1.25c0.344 0 0.625 -0.281 0.625 -0.625v-1.875H0.625zm3.75 -4.375a0.625 0.625 0 1 0 -1.25 0 0.625 0.625 0 1 0 1.25 0zm-1.875 0a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 1 1 -2.5 0zm13.75 0.625a0.625 0.625 0 1 0 0 -1.25 0.625 0.625 0 1 0 0 1.25zm0 -1.875a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 1 1 0 -2.5z"/>
        </g>
      </g>
    </g>
  </svg>`;

  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
}

export default landMarker;