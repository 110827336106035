import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function ChatboxMessage({ message, displayAs }) {
  const currentDateTime = moment(message.createdAt);
  const dateTimeWithBrowserTimezone = currentDateTime.local();
  const currentDateTimeZone = dateTimeWithBrowserTimezone.format(
    'DD/MM/YYYY HH:mm:ss',
  );

  const { t } = useTranslation('bookings');

  return message.senderType !== 'system' ? (
    <div
      className={[
        'p-4 border rounded-lg',
        message.senderType === displayAs
          ? message.private
            ? 'ml-[25%] bg-blue-500 text-blue-50'
            : 'ml-[25%] bg-orange-500 text-orange-50'
          : message.private
          ? 'mr-[25%] bg-blue-50 text-gray-700'
          : 'mr-[25%] bg-gray-100 text-gray-700',
      ].join(' ')}
    >
      <div className="flex justify-between">
        <div className="flex items-center space-x-2">
          <div className="text-base font-semibold">
            {message.user
              ? `${message?.user?.firstName} ${message?.user?.lastName}`
              : t('chat.anonymous')}
          </div>

          <div className="border border-black/10 px-1 rounded">
            <div className="text-sm font-base uppercase">
              {message.senderType}
            </div>
          </div>

          {message.private && (
            <div className="border border-black/10 px-1 py-0.5 rounded">
              <svg className="h-4 w-4" viewBox="0 0 640 512">
                <path d="M48.4 14.8L29.4 .1 0 38 19 52.7 591.5 497.2l19 14.7L639.9 474l-19-14.7L524 384.1c41.9-44 70.2-93.9 84-128.1C578 181.3 478.4 32 320 32c-66.9 0-123.2 26.6-168.3 63L48.4 14.8zM190.8 125.4C227.6 98 270.8 80 320 80c63 0 116.2 29.5 158.9 70.6c35.6 34.3 61.5 74.5 76.6 105.4c-14.1 28.9-37.6 65.8-69.6 98.5L434 314.2c8.9-17.5 14-37.2 14-58.2c0-70.7-57.3-128-128-128c-8.6 0-17 .8-25.1 2.5c-22.5 4.5-42.9 14.9-59.5 29.5l-44.6-34.6zM395 283.9l-82.2-63.8-8.5-42.6c5.1-1 10.3-1.5 15.7-1.5c44.2 0 80 35.8 80 80c0 9.8-1.8 19.2-5 27.9zm8.4 129.9C377.9 425.3 350.1 432 320 432c-63.1 0-116.2-29.5-158.9-70.6C125.6 327.2 99.7 286.9 84.5 256c9.1-18.7 22.2-40.7 38.9-62.8L85.7 163.5C60.2 197.1 42.1 230.8 32 256c30 74.7 129.6 224 288 224c46.9 0 88.6-13.1 124.9-33.4l-41.6-32.7zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" />
              </svg>
            </div>
          )}
        </div>
        <div>{currentDateTimeZone}</div>
      </div>
      <div
        className={`prose prose-sm ${
          message.senderType === displayAs ? 'text-white' : 'text-gray-600'
        }`}
        dangerouslySetInnerHTML={{ __html: message.message }}
        data-cy='message'
      />
    </div>
  ) : (
    <div className="text-center text-gray-500">{message.message}</div>
  );
}
