import getSymbolFromCurrency from 'currency-symbol-map';
import { snakeCase, pick, orderBy } from 'lodash';
import paths from 'deepdash/paths';
import {
  format,
  startOfYesterday,
  startOfMonth,
  endOfMonth,
  addYears,
  parseISO,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import Dinero from 'dinero.js';
import hresa from '../images/bedBanks/h-resa.png';
import expedia from '../images/bedBanks/expedia.png';
import dmc from '../images/bedBanks/dmc.png';

export const formatImageLinkForCss = link => {
  return "'" + encodeURI(link) + "'";
};

export const formatDate = (date, toFormat) => {
  try {
    if (!date || !toFormat) {
      return '';
    }
    const parsedDate = typeof date === 'string' ? parseISO(date) : date;
    return format(parsedDate, toFormat, { locale: enUS });
  } catch (err) {
    return '';
  }
};
export const formatDateWithTimezone = date => {
  const formater = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC',
    timeZoneName: 'short',
  });
  try {
    return formater.format(new Date(date));
  } catch (err) {
    return '';
  }
};
export const getYesterday = () => startOfYesterday();

export const getFirstAndLastDayOfMonth = () => {
  const date = new Date();
  return { first: startOfMonth(date), last: endOfMonth(date) };
};

export const getOneYearPeriod = () => {
  const date = new Date();
  return { first: addYears(date, -1), last: date };
};

export const remove = (list, itemValue) =>
  list.filter(item => item.value !== itemValue);

export const initialLocale = {
  value: 'en',
  label: 'EN',
  alpha2: 'gb',
};

export const getRole = role => {
  switch (role) {
    case 'koob_admin':
      return 'user:roles.koobAdmin';
    case 'dmc_admin':
      return 'user:roles.dmcAdmin';
    case 'super_admin':
      return 'user:roles.superAdmin';
    case 'to_admin':
      return 'user:roles.toAdmin';
    case 'to_user':
      return 'user:roles.toUser';
    default:
      return 'user:roles.dmcUser';
  }
};

export const extractValues = (schema, values) => {
  const schemaPaths = paths(schema.default());
  return pick(values, schemaPaths);
};

export const constantCase = input => snakeCase(input).toUpperCase();

export const formatOrderCriteria = ({ order, direction }) => {
  if (!Boolean(order)) {
    return {
      column: 'UPDATED_AT',
      direction: 'DESC'
    };
  }

  return {
    column: constantCase(order),
    direction: constantCase(direction) || 'ASC',
  };
};

export const pluralize = (input, count) =>
  input
    .split(' ')
    .map(i => `${i}${count > 1 ? 's' : ''}`)
    .join(' ');

export const getActionsCellWidth = actionsCount =>
  24 * actionsCount + 8 * (actionsCount - 1) + 16 * 2;

export const readFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

const sortFacilitiesByGroup = facilities => {
  let result = {};

  facilities.forEach(facility => {
    facility?.groups?.nodes?.forEach(group => {
      if (Boolean(result[group.id])) {
        result[group.id].facilities.push(facility);
      } else {
        result[group.id] = {
          group,
          facilities: [facility],
        };
      }
    });
  });
  return Object.values(result);
};
export const formatFacilitiesToGroupsFacilities = (
  facilities,
  othersLabel = 'Others',
) => {
  if (facilities?.length === 0) {
    return [];
  }

  const [facilitiesWithGroups, facilitiesWithoutGroups] = facilities.reduce(
    (acc, curr, index) => {
      const facility = { ...(curr?.node || curr) };
      const accIndex = facility?.groups?.nodes?.length > 0 ? 0 : 1;
      acc[accIndex].push({
        ...facility,
        index,
      });
      return acc;
    },
    [[], []],
  );

  const sortedFacilitiesByGroup = sortFacilitiesByGroup(facilitiesWithGroups);

  const reorderGroupsFacilities = orderBy(
    sortedFacilitiesByGroup,
    ['group.displayName'],
    ['asc'],
  );

  if (facilitiesWithoutGroups.length === 0) {
    return reorderGroupsFacilities;
  }

  const groupsFacilitiesAndOthers = reorderGroupsFacilities.concat([
    {
      group: { displayName: othersLabel, id: 'others' },
      facilities: facilitiesWithoutGroups,
    },
  ]);

  return groupsFacilitiesAndOthers;
};

export const getBedBankName = (code, organizationName = null) => {
  switch (code) {
    case 'HRL':
      return 'H-resa';
    case 'RXP':
      return 'Expedia';
    default:
      return organizationName === null || organizationName === 'null'
        ? 'DMC Contract'
        : organizationName;
  }
};

export const getBedBankLogo = (code, organizationLogo) => {
  switch (code) {
    case 'HRL':
      return hresa;
    case 'RXP':
      return expedia;
    default:
      return organizationLogo === null || organizationLogo === 'null'
        ? dmc
        : organizationLogo;
  }
};

export const formatCurrency = ({ amount, currency }) => {
  if (!amount && amount !== 0) {
    return '';
  }

  try {
    return Dinero({ amount: Math.round(amount * 100), currency })
      .setLocale('fr')
      .toFormat()
      .toUpperCase();
  } catch (err) {
    return amount;
  }
};

export const phoneNumberRegex = /^[+]?[0-9]{10,15}$/;

export const formatCurrencyLabel = currency =>
  `${currency.toUpperCase()}  ${getSymbolFromCurrency(currency)}`;

export const settingRequestState = ['pending', 'refused'];

export const stateEnum = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  VALIDATED: 'validated',
  REFUSED: 'refused',
  CANCELED: 'canceled',
  SENT: 'sent',
  NOT_REQUESTED: 'not_requested',
  INSTANT_BOOKING: 'instant_booking',
  IN_PROGRESS: 'in_progress',
  STAND_BY : 'stand_by'
};

export const viewKind = {
  LIST: 'list',
  MAP: 'map',
}

export const permissionsEnum = {
  READ_BOOKINGS: 'readBookings',
};

export const encodeId = (model, id) => {
  const capitalizedModelName = model.charAt(0).toUpperCase() + model.slice(1);
  return btoa(`gid://phh/${capitalizedModelName}/${id}`);
};

export const decodeId = idKoob => {
  if (!idKoob) {
    return null;
  }
  try {
    const result = parseInt(idKoob);
    if (!isNaN(result)) {
      return result;
    }
  } catch (e) {
    return null;
  }
  const decoded = atob(idKoob.replace('\n', ''));
  return parseInt(decoded.substring(decoded.lastIndexOf('/') + 1));
};

export const fluxesWithAllotment = {
  ISX: 'ISX',
  FEL: 'FEL',
  HRC: 'HRC',
  SGA: 'SGA',
  LHW: 'LHW',
  SLH: 'SLH',
  SNH: 'SNH',
  LCS: 'LCS',
};

export const roomTags = {
  REFUNDABLE: 'room_refundable',
  NON_REFUNDABLE: 'room_non_refundable',
};

export const koobRole = {
  KOOB_ADMIN: 'koob_admin',
  DMC_ADMIN: 'dmc_admin',
  DMC_USER: 'dmc_user'
}

export const ProductTypeEnum = {
  EXPERIENCE: 'experience',
  HOTEL: 'hotel',
  TRIP: 'trip'
}

export const parseBoolean = value => {
  if (value === 'true' || value === true) {
    return true;
  }

  if (value === 'false' || value === false) {
    return false;
  }

  return undefined;
};