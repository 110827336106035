import moment from 'moment';
import { cva } from 'class-variance-authority';
import { Tooltip } from '../../../components';
import { useTranslation } from 'react-i18next';
import { AllotmentDayStateEnum } from "@koob/enums";

const dayBadge = cva([
  'relative w-12 h-5 rounded border border-gray-200',
  'flex justify-center items-center text-white',
], {
  variants: {
    state: {
      'on_request': 'bg-k-orange',
      'stop_sale': 'bg-red-500',
      'free_sale': 'bg-emerald-400',
      allotment: 'bg-green-600',
    },
    selected: {
      true: 'ring-2 ring-offset-1 ring-blue-500',
      false: 'hover:ring-2 ring-blue-400',
    },
    disabled: {
      true: 'opacity-50 cursor-not-allowed hover:ring-0',
      false: 'cursor-pointer',
    }
  }
});

export default function AllotmentsEditorRoomDay({ organizationId, room, date, onClick, selected, disabled, showSharedAllotments }) {
  const { t } = useTranslation('allotments');

  const organizationAllotments = room.organizations?.find(
    (org) => parseInt(org.id) === organizationId
  );
  const allotmentDay = organizationAllotments?.allotmentDays?.find(
    (aDay) => {
      return moment(aDay.day).isSame(date, 'day') && !aDay.shared;
    }
  ) ?? {
    state: 'on_request'
  };
  const sharedAllotment = organizationAllotments?.allotmentDays?.find(
    (saDay) => {
      return moment(saDay.day).isSame(date, 'day')
        && saDay.shared
    }
  ) ?? {
    count: 0
  };

  const showAllotmentsValue = allotmentDay?.state === AllotmentDayStateEnum.ALLOTMENT;

  const showAllotmentReleaseDaysTooltip = !disabled && (
    [AllotmentDayStateEnum.ALLOTMENT, AllotmentDayStateEnum.FREE_SALE].includes(allotmentDay?.state)
    || (showSharedAllotments && sharedAllotment?.count > 0)
  );

  const state = allotmentDay?.state === AllotmentDayStateEnum.ALLOTMENT && allotmentDay?.count <= 0
    ? 'allotment_empty'
    : allotmentDay?.state;

  return (
    <Tooltip
      tip={t('editor.allotmentReleaseDays', { count: allotmentDay?.allotmentReleaseDays ?? '0' })}
      position="top"
      id={date?.date()}
      disabled={!showAllotmentReleaseDaysTooltip}
    >
      <div
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
        data-cy={date?.date()}
        className={dayBadge({ state: allotmentDay?.state, selected, disabled })}
      >
        {state === 'allotment_empty' && (
          <svg className="absolute inset-0 w-full h-full" preserveAspectRatio="none" viewBox="0 0 100 100">
            <defs>
              <pattern id="diagonalLines" patternUnits="userSpaceOnUse" width="10" height="10">
                <path d="M-1,1 l2,-2 M0,10 l10,-10 M9,11 l2,-2" stroke="white" strokeWidth="2" opacity="1"/>
              </pattern>
            </defs>
            <rect width="100" height="100" fill={state === 'allotment_empty' ? 'url(#diagonalLines)' : 'none'}/>
          </svg>
        )}

        {!selected && (
          <div className="relative z-10 flex items-center justify-center w-full h-full text-white">
            {showAllotmentsValue && (
              <>
                {allotmentDay.count ?? '0'}
              </>
            )}

            {showSharedAllotments && (
              <>
                <i className="fa-light fa-pipe mx-1" />
                {sharedAllotment.count}
              </>
            )}
          </div>
        )}

        {selected && (
          <i className="far fa-check"/>
        )}
      </div>
    </Tooltip>
  );
}
