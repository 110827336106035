import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { Legend, Stack } from '@koob/margaret';
import { DateRange, Tooltip } from 'components';
import HotelDetailCard from 'components/HotelKeeper/HotelDetailCard';
import { IcOrganization } from 'components/icons';
import { IoMoonOutline, IoPeopleOutline } from 'react-icons/io5';
import { FiUsers } from 'react-icons/fi';
import { IcHotel } from 'components/icons';
import IcLightning from 'components/icons/IcLightning';
import UI, {
  RoomTag,
  NoteCard,
  TourOperator,
} from 'components/BookingTunnel/UIComponents';
import {
  MdMailOutline,
  MdPhone,
  MdCalendarToday,
  MdArrowForward,
} from 'react-icons/md';
import { CardTitle, Input, SectionCard, Text } from 'ui';
import { formatDate } from 'utils';
import { isEqual, sum } from 'lodash';
import RoomTags from 'components/Booking/RoomTags';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import KoobButton from 'components/Koob/KoobButton';
import { PromotionTag } from '@koob/ui';

const ItemCard = styled(Stack).attrs({
  size: 'full',
  padding: 0.5,
  gutterSize: 0.5,
})`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const NoteBloc = styled(Text)`
  max-height: 260px;
  overflow: auto;
`;

const DetailsCard = ({ index, composition, allotment, state, rooms }) => {
  const { t } = useTranslation(['bookings', 'bookingForm', 'misc']);
  const theme = useTheme();
  const hotelRoom = composition?.hotelRoom;
  const travelers = composition?.travelers ?? [];
  const travelersAmount = travelers?.length ?? 0;
  const room = rooms?.find(({ roomid }) => roomid === composition?.sourceRoomId);
  const bedRooms = composition?.bedCompositions
    ?.map(bedCompo => `${bedCompo.quantity} ${bedCompo.label}`)
    ?.join(', ');
  return (
    <UI.DetailsSectionCard key={index}>
      <Stack
        direction="row"
        gutterSize={1}
        alignY="start"
        style={{
          marginBottom: theme.spacing(0.5),
          justifyContent: 'space-between',
        }}
      >
        <UI.CardTitle as="h2" style={{ width: 'fit-content' }}>
          {room?.room_description ?? hotelRoom?.displayName ?? 'N/A'}
        </UI.CardTitle>
        <Stack direction="column" alignX="end">
          <Stack direction="row" gutterSize={1}>
            <Text type="bodyLarge" color="text" fontWeight={700}>
              {t('bookingRate')}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" gutterSize={0.5}>
        <RoomTag
          value={t('nGuests', {
            count: travelersAmount,
          })}
          icon={FiUsers}
        />
        {bedRooms && <RoomTag value={bedRooms} icon={IcHotel} />}
      </Stack>
      {allotment && (
        <Stack direction="row" gutterSize={0.5}>
          <RoomTag value="Allotment" icon={IcLightning} />
        </Stack>
      )}
      {composition?.promotions?.length > 0 && (
        <Stack
          style={{
            flexWrap: 'wrap',
            gap: '0.5rem',
          }}
        >
          {composition?.promotions?.map((promotion, index) => (
            <PromotionTag
              key={index}
              name={promotion?.value}
              kind={promotion?.promotionKind}
            />
          ))}
        </Stack>
      )}

      {composition?.tags?.length > 0 && (
        <Stack
          style={{
            flexWrap: 'wrap',
            gap: '0.5rem',
          }}
        >
          <RoomTags room={composition} />
        </Stack>
      )}

      {state === 'confirmed' && (
        <ul style={{ paddingTop: '1rem' }}>
          {travelers?.map((traveler, index) => (
            <UI.DetailSectionItem key={index}>
              <UI.UserIcon kind={traveler?.kind} color={theme.textLighter} />
              <UI.DetailsSectionItemInner>
                <UI.Text isName>
                  {traveler?.firstName} {traveler?.lastName}
                </UI.Text>
                <Stack direction="row" alignY="center">
                  <MdCalendarToday
                    style={{ color: theme.textLighter }}
                    size={18}
                    aria-hidden="true"
                  />
                  <time
                    dateTime={traveler?.birthdate}
                    style={{ marginLeft: theme.spacing(0.5) }}
                  >
                    {traveler?.birthdate
                      ? formatDate(traveler?.birthdate, 'dd / MM / yyyy')
                      : 'N/A'}
                  </time>
                </Stack>
                {traveler?.nationality && (
                  <Stack as="dl" direction="column" className="full">
                    <Stack>
                      <dt>{t('nationality', { ns: 'bookingForm' })} :</dt>
                      <dd>&nbsp;{traveler?.nationality}</dd>
                    </Stack>
                  </Stack>
                )}
                {traveler?.passportNumber && (
                  <Stack as="dl" direction="column" className="full">
                    <Stack>
                      <dt>{t('passportNumber', { ns: 'bookingForm' })} :</dt>
                      <dd>&nbsp;{traveler?.passportNumber}</dd>
                    </Stack>
                    <Stack>
                      <dt>{t('expirationDate', { ns: 'bookingForm' })} :</dt>
                      <dd>
                        &nbsp;
                        <time dateTime={traveler?.expirationDate}>
                          {formatDate(
                            traveler?.expirationDate,
                            'dd / MM / yyyy',
                          )}
                        </time>
                      </dd>
                    </Stack>
                  </Stack>
                )}
              </UI.DetailsSectionItemInner>
            </UI.DetailSectionItem>
          ))}
        </ul>
      )}
    </UI.DetailsSectionCard>
  );
};

const DateChunk = styled.span`
  text-align: center;
  white-space: nowrap;
`;

const DateRangeHotelKeeper = ({ startAt, endAt, size }) => (
  <Stack gutterSize={0.5} size={size} alignX="space-between">
    {Boolean(startAt) ? (
      <DateChunk>
        {format(parseISO(startAt), 'dd MMM yyyy').toUpperCase()}
      </DateChunk>
    ) : (
      <DateChunk>--/--/----</DateChunk>
    )}
    <MdArrowForward size={20} />
    {Boolean(endAt) ? (
      <DateChunk>
        {format(parseISO(endAt), 'dd MMM yyyy').toUpperCase()}
      </DateChunk>
    ) : (
      <DateChunk>--/--/----</DateChunk>
    )}
  </Stack>
);

const BookingRecap = ({
  booking,
  onSubmit,
  isSubmitting,
  hotelKeeper = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('bookings');
  const [hotelConfirmationNumber, setHotelConfirmationNumber] = useState(null);

  return (
    <UI.Wrapper>
      <Stack direction="column" gutterSize={1} style={{ flex: 2 }}>
        <CardTitle>{t('bookingDetails', { ns: 'bookings' })}</CardTitle>

        <SectionCard>
          <Stack direction="column" gutterSize={1} size="full">
            <HotelDetailCard hotel={booking?.hotel} />
            <Stack
              size="full"
              alignX="space-between"
              alignY="center"
              gutterSize={2}
            >
              <ItemCard>
                {!hotelKeeper ? (
                  <DateRange
                    startAt={booking?.startAt}
                    endAt={booking?.endAt}
                    size="full"
                  />
                ) : (
                  <DateRangeHotelKeeper
                    startAt={booking?.startAt}
                    endAt={booking?.endAt}
                    size="full"
                  />
                )}
              </ItemCard>

              {Boolean(booking?.startAt) && Boolean(booking?.endAt) && (
                <Stack gutterSize={0.25} alignY="center">
                  <IoMoonOutline size={18} />
                  <Legend style={{ whiteSpace: 'nowrap' }}>
                    {t('nNightsStay', {
                      count: booking?.numberOfNights,
                      days: booking?.numberOfNights + 1,
                    })}
                  </Legend>
                </Stack>
              )}

              <ItemCard>
                <Stack gutterSize={0.25} alignY="center">
                  <IoPeopleOutline size={18} />
                  <span>
                    {t('nGuests', {
                      count: sum(
                        (booking?.roomCompositions || []).map(
                          ({ travelers }) => travelers?.length || 0,
                        ),
                      ),
                    })}
                  </span>
                </Stack>
                <Stack gutterSize={0.25} alignY="center">
                  <IcOrganization size={18} />
                  <span>
                    {t('nRooms', {
                      count: (booking?.roomCompositions || []).length,
                    })}
                  </span>
                </Stack>
              </ItemCard>
            </Stack>

            <Stack
              size="full"
              alignY="center"
              gutterSize={1}
              alignX="space-between"
            >
              <Stack alignY="center" gutterSize={1}>
                <div>
                  <Text fontWeight={700} type="bodyLarge">
                    {t('total')}
                  </Text>
                </div>
                {booking?.allotmentAvailable && (
                  <Tooltip
                    tip={t('allotments:tooltip')}
                    hasArrow={false}
                    color="purple"
                  >
                    <IcLightning size={24} />
                  </Tooltip>
                )}
              </Stack>
              <Stack gutterSize={1} alignY="center">
                <Text color="orange" type="bodyLarge" fontWeight={700}>
                  {t('bookingRate')}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </SectionCard>

        <CardTitle>{t('notes', { ns: 'bookingForm' })}</CardTitle>
        <SectionCard>
          {booking?.note ? (
            <NoteCard>
              <NoteBloc type="body" color="black">
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: booking?.note,
                  }}
                />
              </NoteBloc>
            </NoteCard>
          ) : (
            <Text
              as="div"
              type="bodySmall"
              color="gray"
              style={{ textAlign: 'center' }}
            >
              {t('emptyNotes', { ns: 'bookings' })}
            </Text>
          )}
        </SectionCard>

        {/* Rooms details DMC */}
        <CardTitle>{t('roomDetails')}</CardTitle>
        {booking?.roomCompositions?.map((composition, index) => {
          return (
            <DetailsCard
              key={index}
              state={booking?.state}
              composition={composition}
              index={index}
              currency={booking?.currency}
              allotment={booking?.allotmentAvailable}
              rooms={booking?.rooms}
            />
          );
        })}
      </Stack>

      {/* Right column */}
      {hotelKeeper && (
        <Stack direction="column" style={{ flex: 1 }} gutterSize={1}>
          <div className=" flex flex-col space-y-2 w-full">
            <div className="mt-6">
              <CardTitle>{t('hotelConfirmationNumber')}</CardTitle>
              <Input
                className="mt-2 pt-4"
                defaultValue={booking?.hotelConfirmationNumber}
                onChange={event => {
                  setHotelConfirmationNumber(event.target.value);
                }}
              />
            </div>
            {hotelConfirmationNumber &&
              !isEqual(
                hotelConfirmationNumber,
                booking?.hotelConfirmationNumber,
              ) && (
                <div>
                  <KoobButton
                    type="button"
                    onClick={() => {
                      onSubmit({ hotelConfirmationNumber });
                    }}
                    loading={isSubmitting}
                  >
                    {t('misc:save')}
                  </KoobButton>
                </div>
              )}
          </div>
          {booking?.showToTa && (
            <>
              <CardTitle>
                {t('bookingReferee', { ns: 'bookingForm' })}
              </CardTitle>
              <SectionCard>
                <Stack direction={{ default: 'column' }} gutterSize={0.5}>
                  <Stack size="full" direction="column" gutterSize={0.5}>
                    <strong>
                      {booking?.customerFirstname} {booking?.customerLastname}
                    </strong>
                    {booking?.customerCompany && (
                      <Text>{booking?.customerCompany}</Text>
                    )}
                    <Text>{booking?.customerAddress}</Text>
                    {booking?.customerAdditionalAddress && (
                      <Text>{booking?.customerAdditionalAddress}</Text>
                    )}
                    <Text>
                      {booking?.customerZipCode} {booking?.customerCity}{' '}
                    </Text>
                    {Boolean(booking?.customerAdditionalAddress) && (
                      <Text color="textLight">
                        {booking.customerAddressTwo}
                      </Text>
                    )}
                  </Stack>

                  <Stack size="full" direction="column" gutterSize={0.25}>
                    <Stack alignY="center" gutterSize={0.5}>
                      <Stack>
                        <MdMailOutline fill={theme.textLighter} size={18} />
                      </Stack>
                      <Text>{booking?.customerEmail}</Text>
                    </Stack>
                  </Stack>
                  <Stack alignY="center" gutterSize={0.5}>
                    <Stack>
                      <MdPhone fill={theme.textLighter} size={18} />
                    </Stack>
                    <Text>{booking?.customerPhoneNumber}</Text>
                  </Stack>
                </Stack>
              </SectionCard>
              {/* Tour operator */}
              <CardTitle>{t('tourOperator', { ns: 'bookingForm' })}</CardTitle>
              <SectionCard>
                <TourOperator
                  operator={{
                    operatorTourName: booking?.operatorTourName,
                    networkName: booking?.networkName,
                  }}
                >
                  <Text
                    type="bodySmall"
                    color="gray"
                    as="div"
                    style={{ textAlign: 'center' }}
                  >
                    {t('emptyTourOperator', { ns: 'bookings' })}
                  </Text>
                </TourOperator>
              </SectionCard>
            </>
          )}
        </Stack>
      )}
    </UI.Wrapper>
  );
};

export default BookingRecap;
