import { Routes, Route, Navigate } from 'react-router-dom';
import ApiKeyEdit from './ApiKeyEdit';
import ApiKeyCreate from './ApiKeyCreate';
import ApiKeyList from './ApiKeyList';
import ApiKeyArchive from './ApiKeyArchive';
import { useApp } from 'hooks';

const OrganizationApiKeys = () => {
  const { isAllowedTo } = useApp();

  return (
    <>
      <Routes>
        {isAllowedTo('manageHotelSettings') && (
          <>
            <Route path="new" element={<ApiKeyCreate />} />
            <Route
              path=":apiKeyId/archive"
              element={<ApiKeyArchive />}
            />
            <Route
              path=":apiKeyId/edit"
              element={<ApiKeyEdit />}
            />
          </>
        )}
        <Route path=":apiKeyId" element={<Navigate to=".." />} />
        <Route element={<ApiKeyList />} />
      </Routes>
    </>
  );
};

export default OrganizationApiKeys;
