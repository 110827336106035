import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button } from 'components';
import {
  TextField,
  SegmentedControlField,
} from 'components/Fields';
import { useTranslation } from 'react-i18next';
import { Stack } from '@koob/margaret';
import { FormLabel, Text } from "ui";
import { ApiKeyRoles } from "@koob/enums";
import KoobInputCheckbox from "../../../../components/Koob/Input/KoobInputCheckbox";
import LocalMultiSearchableSelectField from "../../../../components/Fields/LocalMultiSearchableSelectField";
import { useAsync } from "react-async";
import { getOrganizations } from "@koob/to/src/api/organizations";

export default function ApiKeyForm({
  onSubmit,
  apiKey
}) {
  const { t } = useTranslation('organizationApiKeys');

  const { data } = useAsync({
    promiseFn: getOrganizations
  });
  const organizations = (data?.data ?? [])?.map(organization => ({
    label: organization.displayName,
    value: organization.id,
  }));

  return (
    <Formik
      initialValues={{
        type: apiKey?.type ?? 'dmc',
        name: apiKey?.name ?? '',
        apiKey: apiKey?.apiKey ?? '',
        roles: apiKey?.roles ?? [],
        organizationIds: (apiKey?.organizations ?? [])?.map(organization => ({
          label: organization.displayName,
          value: organization.id,
        })),
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        type: Yup.string().required(t('errors:required')),
        name: Yup.string().required(t('errors:required')),
      })}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <Stack direction="column" gutterSize={1} size="full" alignX="stretch">
            <Stack alignX="space-between" alignY="center" gutterSize={1}>
              <Text type="h1">
                {t(
                  Boolean(apiKey)
                    ? apiKey?.name
                    : 'new',
                )}
              </Text>
            </Stack>

            <SegmentedControlField
              label={t('type')}
              name="type"
              options={[
                { label: t('dmc'), value: 'dmc' },
                { label: t('to'), value: 'to' },
              ]}
              required
            />

            <TextField label={t('name')} name="name" />

            {apiKey && (
              <TextField
                label={t('apiKey')}
                name="apiKey"
                disabled
              />
            )}

            <LocalMultiSearchableSelectField
              label={t('organizations')}
              name="organizationIds"
              options={organizations}
              renderOption={option => option.label}
              renderSelectedOption={option => option.label}
            />

            <div>
              <Stack alignX="space-between" alignY="center">
                <FormLabel>
                  {t('permissions')}
                </FormLabel>
              </Stack>

              {Object.values(ApiKeyRoles)?.map(role => (
                <KoobInputCheckbox
                  key={role}
                  label={t(`roles:${role}`)}
                  value={values.roles.includes(role)}
                  setValue={value => {
                    if (value) {
                      return setFieldValue(
                        'roles',
                        values.roles.concat(role),
                      );
                    }

                    return setFieldValue(
                      'roles',
                      values.roles.filter(
                        permission => permission !== role,
                      ),
                    );
                  }}
                />
              ))}
            </div>

            <Stack gutterSize={1}>
              <Button
                variant="primary"
                type="submit"
                isLoading={isSubmitting}
              >
                {Boolean(apiKey) ? t('misc:saveChanges') : t('create')}
              </Button>

              <Button
                variant="simple"
                type="button"
                to="/organization-api-keys"
              >
                {t('misc:cancel')}
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
