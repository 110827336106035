import AllotmentsEditorHeader from './AllotmentsEditorHeader';
import AllotmentsEditorRoom from './AllotmentsEditorRoom';
import KoobPlaceholder from '../../../components/Koob/KoobPlaceholder';
import { useField } from 'formik';
import AllotmentsEditorEditButton from './AllotmentsEditorEditButton';
import { useApp } from '../../../hooks';
import { useState } from 'react';
import { decodeId } from '../../../utils';
import AllotmentsEditorOrganizationSelector from './AllotmentsEditorOrganizationSelector';
import { useTranslation } from 'react-i18next';
import { Tooltip } from "../../../components";
import KoobInputCheckbox from "../../../components/Koob/Input/KoobInputCheckbox";
import moment from "moment";

export default function AllotmentsEditorBody({ initialValues, loading, currentMonth, daysInMonth, selectedDays, setSelectedDays, reload }) {
  const { t } = useTranslation('contracts');
  const { currentOrganizationId } = useApp();
  const [{ value: rooms }] = useField('rooms');

  const currentOrganizationIdDecoded = decodeId(currentOrganizationId);
  const [organizationId, setOrganizationId] = useState(currentOrganizationIdDecoded);

  const canShowSharedAllotments = organizationId === currentOrganizationIdDecoded;
  const [showSharedAllotments, setShowSharedAllotments] = useState(true);

  const isDaySelected = (date, room) => {
    return selectedDays.some(d => d.roomId === room.id && d.day === date.format('YYYY-MM-DD'));
  };

  const toggleSelectDay = (date, room) => {
    const formattedDate = date.format('YYYY-MM-DD');

    if (isDaySelected(date, room)) {
      setSelectedDays(
        selectedDays.filter(d => d.day !== formattedDate || d.roomId !== room.id)
      );
    } else {
      setSelectedDays([
        ...selectedDays,
        { day: formattedDate, roomId: room.id }
      ]);
    }
  };

  const selectAll = () => {
    setSelectedDays(
      rooms.flatMap(room => {
        return [...Array(daysInMonth)].map((_, i) => ({
          day: moment().month(currentMonth).date(i + 1).format('YYYY-MM-DD'),
          roomId: room.id
        })).filter(d => !moment(d.day).isBefore(moment(), 'day'));
      })
    );
  };

  const deselectAll = () => {
    setSelectedDays([]);
  };

  const selectAllFromRoom = (room) => {
    setSelectedDays([
      ...selectedDays.filter(d => d.roomId !== room.id),
      ...[...Array(daysInMonth)].map((_, i) => ({
        day: moment().month(currentMonth).date(i + 1).format('YYYY-MM-DD'),
        roomId: room.id
      })).filter(d => !moment(d.day).isBefore(moment(), 'day'))
    ]);
  };

  const deselectAllFromRoom = (room) => {
    setSelectedDays(
      selectedDays.filter(d => d.roomId !== room.id)
    );
  };

  const updatedRooms = initialValues?.rooms?.map(item => {
    const findRoom = (rooms ?? []).find(room => room.id === item.id);
    return {
      ...item,
      organizations: findRoom ? findRoom?.organizations : item?.organizations
    }
  });

  return (
    <div className="my-3">
      <div className="bg-gray-50 border-t border-x border-gray-200 rounded-t-md">
        <div className="p-3 w-full flex justify-between items-center">
          <AllotmentsEditorOrganizationSelector
            organizationId={organizationId}
            setOrganizationId={setOrganizationId}
          />

          <div>
            <Tooltip
              tip={t('showSharedTooltip')}
              disabled={!canShowSharedAllotments}
            >
              <KoobInputCheckbox
                label={t('showShared')}
                id="showShared"
                value={showSharedAllotments}
                setValue={(value) => setShowSharedAllotments(value)}
                disabled={!canShowSharedAllotments}
              />
            </Tooltip>
          </div>

          <AllotmentsEditorEditButton
            selectedOrganizationId={organizationId}
            selectedDays={selectedDays}
            rooms={updatedRooms}
          />
        </div>
      </div>

      <div className="overflow-x-auto rounded-md rounded-t-none border border-gray-200 overflow-hidden">
        <div className="min-h-[25vh] inline-block min-w-full">
          <div className="min-w-full divide-y divide-gray-200">
            <AllotmentsEditorHeader
              currentMonth={currentMonth}
              daysInMonth={daysInMonth}
              selectAll={() => selectAll()}
              deselectAll={() => deselectAll()}
            />

            {!loading ? (
              updatedRooms?.map(room => (
                <AllotmentsEditorRoom
                  key={room.id}
                  organizationId={organizationId}
                  room={room}
                  currentMonth={currentMonth}
                  daysInMonth={daysInMonth}
                  toggleSelect={toggleSelectDay}
                  selectAll={() => selectAllFromRoom(room)}
                  deselectAll={() => deselectAllFromRoom(room)}
                  isSelected={isDaySelected}
                  showSharedAllotments={canShowSharedAllotments && showSharedAllotments}
                  reload={reload}
                />
              ))
            ) : (
              <KoobPlaceholder
                className="w-full h-16"
                count={3}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
