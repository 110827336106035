import getSymbolFromCurrency from 'currency-symbol-map';
import { format, parseISO } from 'date-fns';
import { enGB } from 'date-fns/locale';
import Dinero from 'dinero.js';

export const ERRORS = {
  REQUIRED: 'Ce champ est requis',
  TAKEN: 'Cette valeur est déjà prise',
  INVALID: 'Cette valeur n’est pas valide',
  EMAIL: 'Cette adresse mail n’est pas valide',
  EMAIL_NOT_FOUND: 'Cette adresse mail n’est pas enregistrée',
};

export const KoobStateEnum = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  VALIDATED: 'validated',
  REFUSED: 'refused',
  NOT_REQUESTED: 'not_requested',
  CANCELED: 'canceled',
};

export const initialLocale = {
  value: 'en',
  label: 'EN',
  alpha2: 'gb',
};

export const formatDate = (date, frmt) => {
  try {
    if (!date || !frmt) {
      return '';
    }
    const parsedDate = typeof date === 'string' ? parseISO(date) : date;
    return format(parsedDate, frmt, { locale: enGB });
  } catch (err) {
    return '';
  }
};

export const formatDateWithTimezone = (date) => {
  const formater = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC',
    timeZoneName: 'short'
  });
  try {
    return formater.format(new Date(date));
  } catch (err) {
    return '';
  }
}

export const formatImageLinkForCss = link => {
  return '\'' + encodeURI(link) + '\'';
};

export const getRole = role => {
  switch (role) {
    case 'to_admin':
      return 'user:roles.toAdmin';
    default:
      return 'user:roles.toUser';
  }
};

export const getIdToString = (model, id) => {
  const capitalizedModelName = model.charAt(0).toUpperCase() + model.slice(1);
  return btoa(`gid://phh/${capitalizedModelName}/${id}`);
};

export const encodeId = (model, id) => {
  const capitalizedModelName = model.charAt(0).toUpperCase() + model.slice(1);
  return btoa(`gid://phh/${capitalizedModelName}/${id}`);
};

export const decodeId = (idKoob) => {
  if (!idKoob) return null;
  try {
    const result = parseInt(idKoob);
    if (!isNaN(result)) {
      return result;
    }
  } catch (e) {
    return null;
  }
  const decoded = atob(idKoob.replace('\n', ''));
  return parseInt(decoded.substring(decoded.lastIndexOf('/') + 1));
}

export const getActionsCellWidth = actionsCount =>
  24 * actionsCount + 8 * (actionsCount - 1) + 16 * 2;

export const formatCurrencyLabel = currency =>
  `${currency.toUpperCase()}  ${getSymbolFromCurrency(currency)}`;

export const formatCurrency = ({ amount, currency }) => {
  if (!amount && amount !== 0) {
    return '';
  }

  try {
    return Dinero({ amount: Math.round(amount * 100), currency })
      .setLocale('fr')
      .toFormat()
      .toUpperCase();
  } catch (err) {
    return amount;
  }
};

export const phoneRegex =
  /^[+]?[0-9]{10,15}$/;

export const listSupports = [
  {
    name: 'Website',
  },
  {
    name: 'DMC Recommandation',
  },
  {
    name: 'Social media',
  },
  {
    name: 'Event',
  },
  {
    name: 'Advertisement',
  },
  {
    name: 'Mail',
  },
  {
    name: 'Personal',
  },
  {
    name: 'Others',
  },
];
