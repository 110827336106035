const allExperienceMarker = (mainColor, fillColor) => {
  const svgContent = `
    <svg width="45px" height="60px" viewBox="0 0 36 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icons / Maps / Place Hotel Active</title>
      <defs>
          <path d="M18,4 C10.26,4 4,10.26 4,18 C4,28.5 18,44 18,44 C18,44 32,28.5 32,18 C32,10.26 25.74,4 18,4 Z" id="path-1"></path>
          <filter x="-30.4%" y="-16.2%" width="160.7%" height="143.7%" filterUnits="objectBoundingBox" id="filter-2">
              <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
              <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
              <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
              <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
          </filter>
          <filter color-interpolation-filters="auto" id="filter-3">
              <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"></feColorMatrix>
          </filter>
      </defs>
      <g id="Icons-/-Maps-/-Place-Hotel-Active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Path">
              <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
              <use fill="${mainColor}" fill-rule="evenodd" xlink:href="#path-1"></use>
          </g>
          <g transform="matrix(1 0 0 1 18 24)" id="54b94ae6-e59b-47a2-9a4c-cf0c694dce85"  >
          <rect style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;" vector-effect="non-scaling-stroke"  x="-18" y="-24" rx="0" ry="0" width="36" height="48" />
          </g>
          <g transform="matrix(0 0 0 0 0 0)" id="4bfdbf72-fb7f-4847-bffe-c3e48bf811a0"  >
          </g>
          <g transform="matrix(0 0 0 0 0 0)"  >
          <g style=""   >
          </g>
          </g>
         <g transform="matrix(0.03 0 0 0.03 9 13)">
            <g>
              <use fill="${fillColor}" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"/>
              <use fill="${fillColor}" fill-rule="evenodd" xlink:href="#path-1"/>
            </g>

            <!-- Trois ronds horizontaux collés -->
            <g fill="white">
              <circle cx="100" cy="100" r="100"/>
              <circle cx="300" cy="100" r="100"/>
              <circle cx="500" cy="100" r="100"/>
            </g>
          </g>
          <g transform="matrix(0 0 0 0 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(0 0 0 0 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(0 0 0 0 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(0 0 0 0 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(0 0 0 0 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(0 0 0 0 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(0 0 0 0 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(0 0 0 0 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
          <g style=""   >
          </g>
          </g>
      </g>
    </svg>
  `
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
}

export default allExperienceMarker;