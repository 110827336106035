import { cva } from 'class-variance-authority';
import React from 'react';
import CheckoutHotelRoomFooter from './CheckoutHotelRoomFooter';

export default function CheckoutHotelRoomCard({
  scope,
  room,
  parentRoom,
  selectedRoomIndex,
  travelersCount,
  isSelected,
  onSelect,
  children
}) {
  const card = cva([
    'relative isolate w-full bg-white cursor-pointer',
    'overflow',
  ], {
    variants: {
      selected: {
        true: 'ring-k-orange'
      },
      scope: {
        dmc: 'rounded ring-1',
        to: 'shadow-k rounded-lg ring-2',
      }
    },
    compoundVariants: [
      {
        selected: false,
        scope: 'dmc',
        class: 'ring-gray-200',
      },
      {
        selected: false,
        scope: 'to',
        class: 'ring-transparent',
      }
    ]
  });

  const container = cva([], {
    variants: {
      scope: {
        dmc: 'py-3 px-4',
        to: 'py-5 px-6',
      }
    }
  });

  return (
    <div
      className={card({ scope, selected: isSelected })}
      onClick={() => {
        if (!isSelected) {
          onSelect(room.originalIndex);
        }
      }}
    >
      {scope === 'to' && room?.attachments?.length > 0 && (
        <>
          <img
            src={room?.attachments[0]?.url ?? '/img/hotel-bg-placeholder.png'}
            onError={ev => ev.target.src = '/img/hotel-bg-placeholder.png'}
            className="absolute inset-0 -z-10 h-full w-full object-cover blur-sm rounded-lg"
            alt=""
          />

          <div className="absolute inset-0 -z-10">
            <div className="h-full w-full bg-gradient-to-l from-white/75 to-white transition-opacity"/>
          </div>
        </>
      )}

      <div className={container({ scope })}>
        {children}
      </div>

      <CheckoutHotelRoomFooter
        scope={scope}
        variants={parentRoom?.variants}
        selectedRoomIndex={selectedRoomIndex}
        travelersCount={travelersCount}
        onSelectVariant={(index) => onSelect(index)}
      />
    </div>
  );
}
