import React from "react";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { cva } from "class-variance-authority";

export const Lightning = ({ popover, absolute, variant = 'allotment' }) => {
  const popoverHoverFocus = (
    <Popover id="popover-trigger-hover-focus">
      <div className="font-bold bg-gray-100 text-lg rounded-md p-1 h-8">{popover}</div>
    </Popover>
  );

  const lightning = cva([
    'border-2 bg-opacity-60 backdrop-blur-sm rounded-full p-2'
  ], {
    variants: {
      variant: {
        allotment: 'bg-green-600 border-green-600',
        guaranteedDeparture: 'bg-purple-600 border-purple-600',
      }
    }
  });

  const icon = variant === 'allotment' ? 'fas fa-bolt' : 'fas fa-bus';

  return (
    <OverlayTrigger
      trigger={['hover']}
      placement="top"
      overlay={popoverHoverFocus}
    >
      <div className={absolute ? "absolute top-4 right-4" : ""}>
        <div className={lightning({ variant })}>
          <i
            className={[
              icon,
              'text-white h-4 w-4 flex items-center justify-center',
            ].join(' ')}
          />
        </div>
      </div>
    </OverlayTrigger>
  );
};
