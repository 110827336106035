import moment from 'moment/moment';
import AllotmentsEditorRoomSelectButton from "./AllotmentsEditorRoomSelectButton";

export default function AllotmentsEditorHeader({ currentMonth, daysInMonth, selectAll, deselectAll }) {
  return (
    <div className="bg-gray-50 py-3 px-4 text-gray-500 font-medium">
      <div className="flex space-x-3 items-center">
        <div className="w-52 flex justify-between items-center">
          <div>
            {moment().month(currentMonth).format("MMMM YYYY")}
          </div>

          <AllotmentsEditorRoomSelectButton
            selectAll={selectAll}
            deselectAll={deselectAll}
          />
        </div>

        {[...Array(daysInMonth)].map((_, i) => (
          <div
            key={i}
            className="text-center w-12"
          >
            {i + 1 < 10 && '0'}
            {i + 1}
          </div>
        ))}
      </div>
    </div>
  );
}
