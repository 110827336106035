export default function KoobSeparatorLine() {
  return (
    <div className="relative group py-2">
      <div className='w-full h-1 bg-gray-200 transition rounded-full'>
        <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-6 h-6 bg-gray-200 transition rounded-full flex justify-center items-center'>
        </div>
      </div>
    </div>
  )
}
