const hotelMarker = (mainColor, fillColor) => {
  const svgContent = `
      <svg width="45px" height="60px" viewBox="0 0 36 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Icons / Maps / Place Hotel Active</title>
          <defs>
              <path d="M18,4 C10.26,4 4,10.26 4,18 C4,28.5 18,44 18,44 C18,44 32,28.5 32,18 C32,10.26 25.74,4 18,4 Z" id="path-1"></path>
              <filter x="-30.4%" y="-16.2%" width="160.7%" height="143.7%" filterUnits="objectBoundingBox" id="filter-2">
                  <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                  <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                  <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                  <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
              </filter>
              <filter color-interpolation-filters="auto" id="filter-3">
                  <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"></feColorMatrix>
              </filter>
          </defs>
          <g id="Icons-/-Maps-/-Place-Hotel-Active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Path">
                  <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                  <use fill="${mainColor}" fill-rule="evenodd" xlink:href="#path-1"></use>
              </g>
              <g id="Filled-/-maps-/-place" transform="translate(8.000000, 9.000000)" filter="url(#filter-3)">
                  <g>
                    <path d="M6.875,11.9784722 C5.72440677,11.9784722 4.79166667,12.9112123 4.79166667,14.0618056 C4.79166667,15.2123988 5.72440677,16.1451389 6.875,16.1451389 C8.02559323,16.1451389 8.95833333,15.2123988 8.95833333,14.0618056 C8.95833333,12.9112123 8.02559323,11.9784722 6.875,11.9784722 Z M6.875,13.0201389 C7.45029661,13.0201389 7.91666667,13.4865089 7.91666667,14.0618056 C7.91666667,14.6371022 7.45029661,15.1034722 6.875,15.1034722 C6.29970339,15.1034722 5.83333333,14.6371022 5.83333333,14.0618056 C5.83333333,13.4865089 6.29970339,13.0201389 6.875,13.0201389 Z" id="Oval" fill="#000000" fill-rule="nonzero"></path>
                    <path d="M14.3402777,11.9791667 L9.99999994,11.9791667 C9.71235163,11.9791667 9.47916661,12.2123517 9.47916661,12.5 L9.47916661,15.625 C9.47916661,15.9126483 9.71235163,16.1458333 9.99999994,16.1458333 L15.7291666,16.1458333 C16.0168149,16.1458333 16.2499999,15.9126483 16.2499999,15.625 L16.2499999,13.8888889 L16.2444312,13.7469135 C16.158905,12.740575 15.3337437,11.9791667 14.3402777,11.9791667 Z M10.52,15.1041667 L10.52,13.0208333 L14.3402777,13.0208333 C14.7917108,13.0208333 15.1669865,13.3671148 15.2051269,13.8133999 L15.2088039,13.9110256 L15.2075,15.1041667 L10.52,15.1041667 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
                    <path d="M10,15.1034722 C10.2876483,15.1034722 10.5208333,15.3366572 10.5208333,15.6243056 C10.5208333,15.885804 10.3281184,16.1022915 10.0769649,16.1394917 L10,16.1451389 L4.79166667,16.145 L4.79166667,17.7076389 C4.79166667,17.9691373 4.59895177,18.1856248 4.34779827,18.2228251 L4.27083333,18.2284722 C4.00933487,18.2284722 3.79284739,18.0357573 3.75564717,17.7846038 L3.75,17.7076389 L3.75,15.6243056 C3.75,15.3628071 3.9427149,15.1463196 4.1938684,15.1091194 L4.27083333,15.1034722 L10,15.1034722 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
                    <path d="M15.7291667,15.1041667 C15.9906651,15.1041667 16.2071526,15.2968816 16.2443528,15.5480351 L16.25,15.625 L16.25,17.7083333 C16.25,17.9959816 16.016815,18.2291667 15.7291667,18.2291667 C15.4676682,18.2291667 15.2511807,18.0364518 15.2139805,17.7852983 L15.2083333,17.7083333 L15.2083333,15.625 C15.2083333,15.3373517 15.4415184,15.1041667 15.7291667,15.1041667 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
                    <path d="M15.7291667,5.38194444 C15.9906651,5.38194444 16.2071526,5.57465934 16.2443528,5.82581285 L16.25,5.90277778 L16.25,10.4166667 C16.25,10.704315 16.016815,10.9375 15.7291667,10.9375 C15.4676682,10.9375 15.2511807,10.7447851 15.2139805,10.4936316 L15.2083333,10.4166667 L15.2083333,5.90277778 C15.2083333,5.61512947 15.4415184,5.38194444 15.7291667,5.38194444 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
                    <path d="M4.27083333,5.38194444 C4.53233179,5.38194444 4.74881928,5.57465934 4.7860195,5.82581285 L4.79166667,5.90277778 L4.79166667,10.4166667 C4.79166667,10.704315 4.55848164,10.9375 4.27083333,10.9375 C4.00933487,10.9375 3.79284739,10.7447851 3.75564717,10.4936316 L3.75,10.4166667 L3.75,5.90277778 C3.75,5.61512947 3.98318503,5.38194444 4.27083333,5.38194444 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
                    <path d="M9.13333314,2.03399007 C9.61778106,1.71105267 10.2376007,1.68621133 10.7429297,1.95950027 L10.8667321,2.03403358 L18.1014543,6.85833914 C18.3407745,7.01792421 18.4054126,7.34130082 18.2458275,7.58062101 C18.102201,7.79600918 17.8259032,7.86990484 17.5976156,7.76619257 L17.5235457,7.7249942 L10.2888903,2.90073321 C10.1389394,2.80077537 9.95008364,2.78649568 9.78906005,2.85786607 L9.71117657,2.90068864 L2.47645434,7.7249942 C2.23713416,7.88457927 1.91375755,7.8199412 1.75417247,7.58062101 C1.6105459,7.36523284 1.64854009,7.08175897 1.8320396,6.91088056 L1.89854566,6.85833914 L9.13333314,2.03399007 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
                  </g>
              </g>
          </g>
      </svg>
    `;
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
}

export default hotelMarker;