const poiMarker = (mainColor, fillColor) => {
  const svgContent = `<svg width="45px" height="60px" viewBox="0 0 36 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icons / Maps / Place Hotel Active</title>
      <defs>
          <path d="M18,4 C10.26,4 4,10.26 4,18 C4,28.5 18,44 18,44 C18,44 32,28.5 32,18 C32,10.26 25.74,4 18,4 Z" id="path-1"></path>
          <filter x="-30.4%" y="-16.2%" width="160.7%" height="143.7%" filterUnits="objectBoundingBox" id="filter-2">
              <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
              <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
              <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
              <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
          </filter>
          <filter color-interpolation-filters="auto" id="filter-3">
              <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"></feColorMatrix>
          </filter>
      </defs>
      <g id="Icons-/-Maps-/-Place-Hotel-Active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Path">
              <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
              <use fill="${mainColor}" fill-rule="evenodd" xlink:href="#path-1"></use>
          </g>
          <g transform="matrix(0 0 0 0 0 0)" id="4bfdbf72-fb7f-4847-bffe-c3e48bf811a0"  >
          </g>
          <g transform="matrix(1 0 0 1 18 24)" id="54b94ae6-e59b-47a2-9a4c-cf0c694dce85"  >
          <rect style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;" vector-effect="non-scaling-stroke"  x="-18" y="-24" rx="0" ry="0" width="36" height="48" />
          </g>
          <g transform="matrix(0.03 0 0 0.03 18.38 11.5)"  >
          <path fill="${fillColor}" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill-rule: nonzero; opacity: 1;"  transform=" translate(-320, -256)" d="M 45.6 64 C 38.1 64 32 70.1 32 77.6 L 32 434.4 C 32 441.9 38.1 448 45.6 448 C 47.2 448 48.6 447.8 50.1 447.3 C 52.6 446.5 57.2 445 63.7 443 L 67.10000000000001 438.4 L 198.4 263.5 C 211.20000000000002 246.4 236.8 246.4 249.60000000000002 263.5 L 280 304 L 358.4 199.5 C 371.2 182.4 396.79999999999995 182.4 409.59999999999997 199.5 L 588.8 438.4 L 595.9 447.9 C 602.6999999999999 447.09999999999997 608 441.4 608 434.4 L 608 77.6 C 608 70.1 601.9 63.99999999999999 594.4 63.99999999999999 C 592.8 63.99999999999999 591.4 64.19999999999999 589.9 64.69999999999999 C 573.6 70.2 467.1 104 320 104 C 172.89999999999998 104 66.4 70.2 50.2 64.7 C 48.7 64.2 47.2 64 45.7 64 z M 546.1 434.9 L 384 218.7 L 292.8 340.3 L 239.5 411.4 C 264.6 409.29999999999995 291.5 408 320 408 C 418.6 408 499 423.2 546.1 434.9 z M 196 415.9 L 260 330.59999999999997 L 224 282.59999999999997 L 113.3 430.3 C 136 425.3 163.9 420.1 196.1 415.90000000000003 z M 60.4 477.6 C 55.699999999999996 479.20000000000005 50.7 480 45.7 480 C 20.4 480 0 459.6 0 434.4 L 0 77.6 C 0 52.4 20.4 32 45.6 32 C 50.7 32 55.6 32.8 60.3 34.4 C 74.6 39.2 177.6 72 320 72 C 462.4 72 565.4 39.2 579.6 34.4 C 584.3000000000001 32.8 589.3000000000001 32 594.3000000000001 32 C 619.6 32 640 52.4 640 77.6 L 640 434.4 C 640 459.59999999999997 619.6 480 594.4 480 C 589.4 480 584.4 479.2 579.6999999999999 477.6 C 565.4 472.8 462.4 440 320 440 C 177.60000000000002 440 74.6 472.8 60.39999999999998 477.6 z M 112 184 C 112 170.74516600406096 122.74516600406096 160 136 160 C 149.25483399593904 160 160 170.74516600406096 160 184 C 160 197.25483399593904 149.25483399593904 208 136 208 C 122.74516600406096 208 112 197.25483399593904 112 184 z" stroke-linecap="round" />
          </g>
          <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
          <g style=""   >
          </g>
          </g>
          <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
          <g style=""   >
          </g>
          </g>
      </g>
    </svg>`;
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
}

export default poiMarker;