const railMarker = (mainColor, fillColor) => {
  const svgContent = `
  <svg width="45px" height="60px" viewBox="0 0 36 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icons / Maps / Place experience Active</title>
    <defs>
      <path d="M18 4C10.26 4 4 10.26 4 18c0 10.5 14 26 14 26s14 -15.5 14 -26c0 -7.74 -6.26 -14 -14 -14Z" id="path-1"/>
      <filter x="-30.4%" y="-16.2%" width="160.7%" height="143.7%" filterUnits="objectBoundingBox" id="filter-2">
        <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
        <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1"/>
      </filter>
      <filter color-interpolation-filters="auto" id="filter-3">
        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.F5700A 0"/>
      </filter>
    </defs>
    <g id="Icons-/-Maps-/-Place-experience-Active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Path">
        <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"/>
        <use fill="${mainColor}" fill-rule="evenodd" xlink:href="#path-1"/>
      </g>
      <g id="Filled-/-maps-/-place" transform="translate(8 9)" filter="url(#filter-3)">
        <g>
        <path fill="${fillColor}" d="M15.714 0.714c1.973 0 3.571 1.598 3.571 3.571v11.429c0 1.973 -1.598 3.571 -3.571 3.571H4.286c-1.973 0 -3.571 -1.598 -3.571 -3.571V4.286c0 -1.973 1.598 -3.571 3.571 -3.571h11.429zM4.286 0C1.92 0 0 1.92 0 4.286v11.429c0 2.205 1.665 4.022 3.808 4.259l-2.277 2.272c-0.138 0.138 -0.138 0.366 0 0.504s0.366 0.138 0.504 0l2.754 -2.75h10.42l2.754 2.754c0.138 0.138 0.366 0.138 0.504 0s0.138 -0.366 0 -0.504l-2.272 -2.272c2.143 -0.237 3.808 -2.054 3.808 -4.259V4.286c0 -2.366 -1.92 -4.286 -4.286 -4.286H4.286zm7.857 14.286a2.143 2.143 0 1 0 -4.286 0 2.143 2.143 0 1 0 4.286 0zm-2.143 -1.429a1.429 1.429 0 1 1 0 2.857 1.429 1.429 0 1 1 0 -2.857zM4.286 3.571h11.429c0.393 0 0.714 0.321 0.714 0.714v4.286c0 0.393 -0.321 0.714 -0.714 0.714H4.286c-0.393 0 -0.714 -0.321 -0.714 -0.714V4.286c0 -0.393 0.321 -0.714 0.714 -0.714zM2.857 4.286v4.286c0 0.79 0.638 1.429 1.429 1.429h11.429c0.79 0 1.429 -0.638 1.429 -1.429V4.286c0 -0.79 -0.638 -1.429 -1.429 -1.429H4.286c-0.79 0 -1.429 0.638 -1.429 1.429z"/>
        </g>
      </g>
    </g>
  </svg>`;

  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
}

export default railMarker;