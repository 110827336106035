import { memo } from 'react';

const IcPencil = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
    data-cy={props.cypressId}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.81 3.024a3.656 3.656 0 015.122.04c.027.027.051.056.072.086a3.67 3.67 0 01-.059 5.02.628.628 0 01-.061.072l-.221.221a.626.626 0 01-.24.24L8.93 20.196a.624.624 0 01-.526.322l-5.64 1.455a.625.625 0 01-.761-.761l1.484-5.75a.625.625 0 01.163-.286L15.733 3.091a.717.717 0 01.077-.067zM8.366 18.992l10.85-10.85-3.383-3.384L4.98 15.613l3.386 3.379zm-3.94-2.167l2.728 2.723-3.676.949.948-3.672zM16.723 3.88l3.37 3.37a2.42 2.42 0 00-.055-3.303l-.022-.022a.64.64 0 01-.044-.05 2.405 2.405 0 00-3.249.005z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcPencil);
