import { KoobBadge } from "@koob/ui";

export default function ApiKeyOrganizations({ organizations = [] }) {

  return (
    <div className="flex flex-wrap gap-1.5">
      {organizations.map((organization) => (
        <KoobBadge
          key={organization.id}
          color="gray"
          size="sm"
        >
          {organization.displayName}
        </KoobBadge>
      ))}
    </div>
  );
}
