const flagMarker = (mainColor, fillColor) => {
  const svgContent = `
  <svg width="45px" height="60px" viewBox="0 0 36 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>Icons / Maps / Place</title>
    <defs>
        <path d="M18,4 C10.26,4 4,10.26 4,18 C4,28.5 18,44 18,44 C18,44 32,28.5 32,18 C32,10.26 25.74,4 18,4 Z" id="path-1"></path>
        <filter x="-30.4%" y="-16.2%" width="160.7%" height="143.7%" filterUnits="objectBoundingBox" id="filter-2">
            <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Icons-/-Maps-/-Place-Hotel-Active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Path">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
            <use fill="${mainColor}" fill-rule="evenodd" xlink:href="#path-1"></use>
        </g>
        <g id="Filled-/-maps-/-place" transform="translate(8.000000 ,9.000000)" filter="url(#filter-3)">
            <g>
                <g transform="translate(2, 4)">
                    <path fill="${fillColor}" d="M4 2c0-1.1-0.9-2-2-2s-2 0.9-2 2c0 0.7 0.4 1.4 1 1.7v12.3h2v-12.3c0.6-0.3 1-1 1-1.7z"></path>
                    <path fill="${fillColor}" d="M4 4c0 0 1-3 3.6-3 2.7 0 2.3 1 4.4 1 2.4 0 4-1 4-1v8c0 0-0.7 2-4 2-2.2 0-2.3-1-5-1-2.3 0-3 2-3 2v-8z"></path>
                </g>
            </g>
        </g>
    </g>
   </svg>`;

  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
}

export default flagMarker;