import { ExperienceSliderCard } from "@koob/experiences"
import { InfoWindow } from "@react-google-maps/api"
import ExperienceCard from "./ExperienceCard"
import { SwiperSlide } from "swiper/react"

export const ExperienceSlider = ({
    experiences,
    clusterPosition,
    isOpenSlide,
    setIsOpenSlide,
    onSelect,
    getExperienceCompatibility,
    mode
  }) => {

  return (
    <>
      {isOpenSlide && 
        <InfoWindow
          options={{ maxWidth: 400, minWidth: 200}}
          position={{
            lat: clusterPosition?.lat,
            lng: clusterPosition?.lon,
          }}
          onCloseClick={() => setIsOpenSlide(false)}
          className="overflow-hidden"
        >
          <div className="flex justify-center items-center">
            <ExperienceSliderCard>
              {experiences
                ?.filter(exp => (Number(exp.lat) === Number(clusterPosition?.lat) && Number(exp?.lon) === Number(clusterPosition?.lon)))
                ?.map((experience, index) => (
                <SwiperSlide key={index}>
                  <ExperienceCard
                    experience={experience}
                    mode={mode}
                    onSelect={() => onSelect(experience.id)}
                    compatibility={() => getExperienceCompatibility(experience.id)}
                    variant="bare"
                    hasBorder={true}
                  />
                </SwiperSlide>
              ))}
            </ExperienceSliderCard>
          </div>
                      
        </InfoWindow>
      }
    </>
  )
}