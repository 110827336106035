import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Stack, ButtonReset } from '@koob/margaret';
import RightsExplanationModal from 'components/RightsExplanationModal';
import { Button, Tooltip } from 'components';
import { gql, useQuery } from '@apollo/client';
import {
  SelectField,
  TextField,
  RegionsRightsSelectorField,
  SegmentedControlField,
  ToggleSwitchField,
} from 'components/Fields';
import { camelCase, find } from 'lodash';
import * as Yup from 'yup';
import { Text, FormLabel } from 'ui';
import { useUserRights, useSearchParams, useApp } from 'hooks';
import { useAsync } from 'react-async';
import { getMarkets } from 'api/node/markets';
import LocalMultiSearchableSelectField from 'components/Fields/LocalMultiSearchableSelectField';
import { GET_SEGMENTS } from 'containers/ExperiencesSettings/Segments/SegmentsList';
import { getToConnectionRequest } from 'api/node/toConnectionRequest';
import { decodeId } from 'utils';
import ToggleSwitch from 'components/Fields/ToggleSwitch';
import { mergeOrganizationFromToRequestConnectionList } from 'utils/organizations';

export const GET_ORGANIZATIONS = gql`
  query getOrganizations($discarded: Boolean, $isPartner: Boolean) {
    organizations(discarded: $discarded, isPartner: $isPartner) {
      nodes {
        id
        displayName
      }
    }
  }
`;

const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.spacing(0.85)};
  color: ${({ theme }) => theme.textLighter};
`;

const ModalLink = styled(ButtonReset)`
  text-decoration: underline;
  color: ${({ theme }) => theme.secondary};
`;

const UserForm = ({ onSubmit, user, initialOrganization, restrictedRoles }) => {
  const { currentUserRole, canManageUser } = useApp();
  const [{ state }] = useSearchParams();
  const { t } = useTranslation(['user', 'errors']);
  const [showRightsModal, setShowRightsModal] = useState(false);
  const { data } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      discarded: state === 'archived',
      ...(currentUserRole !== 'koob_admin' && { isPartner: false }),
    },
  });
  const rightOptions = useUserRights();

  const organizations = data?.organizations.nodes;

  const isTo =
    initialOrganization?.scope === 'to' ||
    user?.organizations?.nodes[0]?.scope === 'to';

  const { data: segmentsData } = useQuery(GET_SEGMENTS);

  const segments = (segmentsData?.experienceSegments?.edges ?? []).map(
    ({ node }) => ({ label: node.displayName, value: node.id }),
  );

  const { data: marketsData } = useAsync({
    promiseFn: getMarkets,
    filters: {
      order: 'name',
      direction: 'asc',
      page: 0,
      pageSize: 0,
    },
  });

  const markets = (marketsData?.data ?? []).map(({ id, name }) => {
    return {
      label: name,
      value: id,
    };
  });

  const { data: toconnectionRequestData } = useAsync({
    promiseFn: getToConnectionRequest,
  });

  const formattedToRequets = mergeOrganizationFromToRequestConnectionList(toconnectionRequestData);

  const toOrganizations = formattedToRequets.map(({ toorganization }) => ({
    label: toorganization.displayName,
    value: toorganization.id,
  }));

  const formatRights = input =>
    input
      .map(({ name, region }) => ({ right: name, regionId: region?.id }))
      .reduce(
        (acc, { regionId, right }) => ({
          ...acc,
          [regionId]: find(rightOptions, ({ value }) => value === right),
        }),
        {},
      );
  const initialValues = {
    email: user?.email ?? '',
    lastName: user?.lastName ?? '',
    firstName: user?.firstName ?? '',
    role:
      user?.role ??
      (initialOrganization?.scope === 'to' ? 'to_admin' : 'dmc_admin'),
    toRequest: user?.toRequest ?? false,
    organizations: Boolean(user)
      ? {
          value: user?.organizations?.nodes?.[0]?.id,
          label: user?.organizations?.nodes?.[0]?.displayName,
        }
      : Boolean(initialOrganization)
      ? {
          value: initialOrganization?.id,
          label: initialOrganization?.displayName,
        }
      : null,
    rights: formatRights(user?.rights?.nodes ?? []),
    isReferent: user?.isReferent || false,
    markets: (user?.markets?.nodes ?? []).map(({ id, name }) => ({
      label: name,
      value: decodeId(id),
    })),
    segments: (user?.segments?.nodes ?? []).map(({ id, displayName }) => ({
      label: displayName,
      value: id,
    })),
    toOrganizations: (user?.toOrganizations?.nodes ?? []).map(
      ({ id, displayName }) => ({
        label: displayName,
        value: decodeId(id),
      }),
    ),
  };

  const roleOptions = [
    { value: 'koob_admin', label: t('roles.koobAdmin') },
    { value: 'dmc_admin', label: t('roles.dmcAdmin') },
    { value: 'dmc_user', label: t('roles.dmcUser') },
  ];

  const roleOptionsTO = [
    { value: 'to_admin', label: t('roles.toAdmin') },
    { value: 'to_user', label: t('roles.toUser') },
  ];

  const organizationsOptions = organizations?.map(({ id, displayName }) => ({
    label: displayName,
    value: id,
  }));

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('errors:email'))
            .required(t('errors:required')),
          firstName: Yup.string().required(t('errors:required')),
          lastName: Yup.string().required(t('errors:required')),
          role: Yup.string().required(t('errors:required')),
          organizations: Yup.mixed().when('role', role =>
            role !== 'koob_admin'
              ? Yup.mixed().required(t('errors:required'))
              : Yup.mixed(),
          ),
          markets: Yup.array().when('isReferent', {
            is: true,
            then: Yup.array()
              .of(Yup.object())
              .required(t('errors:required'))
              .min(1),
            otherwise: Yup.array().of(Yup.object()),
          }),
          segments: Yup.array().of(Yup.object()),
          toOrganizations: Yup.array().of(Yup.object()),
          isReferent: Yup.boolean(),
        })}
        enableReinitialize
      >
        {({ values, setFieldValue, status, isSubmitting, isValid }) => (
          <Form>
            <Stack direction="column" gutterSize={1}>
              <Text type="h1">
                {Boolean(user)
                  ? `${user.firstName} ${user.lastName}`
                  : t('inviteNewUser')}
              </Text>
              <SegmentedControlField
                name="role"
                options={
                  isTo
                    ? roleOptionsTO
                    : currentUserRole !== 'koob_admin' || restrictedRoles
                    ? roleOptions.slice(1)
                    : roleOptions
                }
                disabled={!canManageUser(user)}
              />

              {Boolean(values.role) && (
                <Subtitle>{t(`${camelCase(values.role)}Explanation`)}</Subtitle>
              )}
            </Stack>

            <Stack direction="column" gutterSize={1.5} size="full">
              <Stack
                gutterSize={1.5}
                size="full"
                direction={{ default: 'column', tablet: 'row' }}
              >
                <TextField label={t('firstname')} name="firstName" />
                <TextField label={t('lastname')} name="lastName" />
              </Stack>
              <TextField label={t('email')} name="email" />
              {values.role === 'dmc_admin' && (
                <Stack alignY="center" gutterSize={1}>
                  <div>
                    <FormLabel>{t('toRequest')}</FormLabel>
                  </div>
                  <ToggleSwitchField name="toRequest" />
                </Stack>
              )}
              {(values.role === 'dmc_admin' ||
                values.role === 'dmc_user' ||
                values?.role === 'to_user' ||
                values?.role === 'to_admin') && (
                <SelectField
                  label={t('organization')}
                  name="organizations"
                  options={organizationsOptions}
                  disabled={Boolean(initialOrganization) || Boolean(user)}
                />
              )}
              {values?.role && !['to_user', 'to_admin'].includes(values?.role) && (
                <>
                  <Stack alignY="center" gutterSize={1}>
                    <Tooltip
                      tip={t('referentTip')}
                      position="top"
                      hasArrow={false}
                      color="purple"
                    >
                      <div className="flex justify-center space-x-1">
                        <FormLabel>{t('referent')}</FormLabel>
                        <i className="fa-regular fa-circle-info fa-lg"></i>
                      </div>
                    </Tooltip>
                    <ToggleSwitch
                      checked={values.isReferent}
                      onChange={() => {
                        setFieldValue('isReferent', !values.isReferent, true);
                      }}
                    />
                  </Stack>
                  <LocalMultiSearchableSelectField
                    options={markets}
                    label={t('markets')}
                    name="markets"
                    renderOption={value => value.label}
                    renderSelectedOption={value => value.label}
                  />

                  <LocalMultiSearchableSelectField
                    options={segments}
                    label={t('segments')}
                    name="segments"
                    renderOption={value => value.label}
                    renderSelectedOption={value => value.label}
                  />
                  <LocalMultiSearchableSelectField
                    options={toOrganizations}
                    label={t('applicableTo')}
                    name="toOrganizations"
                    renderOption={value => value.label}
                    renderSelectedOption={value => value.label}
                  />
                </>
              )}
              {(values.role === 'dmc_user' || values?.role === 'to_user') &&
                Boolean(values?.organizations?.value) && (
                  <>
                    <RegionsRightsSelectorField
                      name="rights"
                      organizationId={values?.organizations?.value}
                      label={t('associatedRights')}
                      sublabel={
                        <Trans i18nKey="user:rightsExplanation">
                          Choose the appropriate rights on the countries &
                          regions of the organization for this user. Consult the
                          help to understand properly{' '}
                          <ModalLink
                            type="button"
                            onClick={() => setShowRightsModal(true)}
                          >
                            the different kind of roles.
                          </ModalLink>
                        </Trans>
                      }
                    />
                  </>
                )}
              {Boolean(status) && status}
              <Stack alignY="center" gutterSize={1}>
                <Button variant="primary" isLoading={isSubmitting} cypressId="invite-user">
                  {Boolean(user) ? t('misc:saveChanges') : t('inviteUser')}
                </Button>
                <Button type="button" variant="simple" to={-1}>
                  {t('misc:cancel')}
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>

      <RightsExplanationModal
        isOpen={showRightsModal}
        onRequestClose={() => setShowRightsModal(false)}
        isTo={isTo}
      />
    </>
  );
};

export default UserForm;
