import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTripDesigner } from 'hooks';
import Tooltip from '../Tooltip';
import React from 'react';
import { formatCurrency } from '../../utils';
import KoobButton from '../Koob/KoobButton';
import KoobPlaceholder from '../Koob/KoobPlaceholder';

const GoToTrip = () => {
  const { t } = useTranslation('experiences');
  const navigate = useNavigate();
  const location = useLocation();
  const { tripFilters, resetContext, tripData } = useTripDesigner();

  const applicableDays = tripData?.days?.filter(
    day => day?.experience || day?.hotel,
  );

  const priceLoading = applicableDays?.some(
    day => day?.hotel?.priceLoading || day?.experience?.priceLoading,
  );

  const globalTripPrice = applicableDays?.reduce((acc, day) => {
    const experience = day?.experience;
    const experiencePrice = parseFloat(
      experience?.previousBooking?.price ??
        experience?.tripPrice?.price ??
        experience?.price?.price ??
        0,
    );

    const hotel = day?.hotel;
    const hotelPrice = parseFloat(
      hotel?.previousBooking?.price ?? hotel?.price ?? 0,
    );

    if (
      experiencePrice !== undefined &&
      (experience?.dayIndex === 1 || !experience?.dayIndex)
    ) {
      acc += experiencePrice;
    }

    if (hotelPrice && !hotel?.isIncluded) {
      acc += hotelPrice;
    }

    return acc;
  }, 0);

  const globalTripCurrency = applicableDays?.reduce((acc, day) => {
    const experience = day?.experience;
    const experienceCurrency =
      experience?.previousBooking?.currency?.toLowerCase() ??
      experience?.tripPrice?.currency?.toLowerCase() ??
      experience?.price?.currency?.toLowerCase();

    const hotel = day?.hotel;
    const hotelCurrency =
      hotel?.previousBooking?.currency ?? hotel?.price
        ? hotel?.previousBooking?.currency?.toLowerCase() ?? hotel?.currency?.toLowerCase()
        : null;
    if (!acc) {
      acc = experienceCurrency ?? hotelCurrency;
    } else if (
      (experienceCurrency && acc !== experienceCurrency) ||
      (hotelCurrency && acc !== hotelCurrency) || (!experienceCurrency)
    ) {
      acc = 'mismatch';
    }

    return acc;
  }, null);

  return tripFilters ? (
    <div className="flex space-x-3 items-center">
      {Object.keys(tripFilters)?.length > 1 ? (
        <>
          <div className="border rounded-md px-4 py-2">
            <div className="text-xs text-gray-600 uppercase font-semibold">
              {t('currentTrip')}
            </div>

            <div className="text-sm text-orange-600 font-medium w-56 overflow-hidden">
              {tripFilters?.programName}
            </div>
          </div>

          {!location.pathname.includes('/compose') && (
            <KoobButton onClick={() => navigate('/trip-designer/compose')}>
              <div className="flex space-x-1.5 items-center">
                <svg className="h-5 w-5" height="1em" viewBox="0 0 576 512">
                  <path d="M181.3 19.3c-25-25-65.5-25-90.5 0L51.3 58.7c-3.1 3.1-5.9 6.5-8.2 10c-16.4 24.8-13.7 58.6 8.2 80.5l88.8 88.8c13.9-4 28.6-6.1 43.9-6.1l1.5 0 8.2-8.2L161 191 223 129l32.8 32.8 33.9-33.9L181.3 19.3zM414.2 320.1l37.3 37.3c.6 .6 1.2 1.2 1.7 1.8c3.9 4.4 6.7 9.6 8.4 15.2l6.9 23.4 16.1 54.8-54.8-16.1-23.4-6.9c-6.4-1.9-12.3-5.4-17-10.1l-37.3-37.3-8.1 8.1 0 1.7c0 15.2-2.1 29.9-6.1 43.9l17.6 17.6c1.3 1.3 2.6 2.6 4 3.8c9.6 8.5 21 14.8 33.4 18.4l78.1 23L513.2 511c8.4 2.5 17.5 .2 23.7-6.1s8.5-15.3 6.1-23.7L530.6 439l-23-78.1c-4.2-14.1-11.8-27-22.2-37.4l-37.3-37.3-33.9 33.9zM519 57c8.3 8.3 8.3 21.8 0 30.1L336.3 269.8l-30.1-30.1L489 57c8.3-8.3 21.8-8.3 30.1 0zM184 320c9.4 0 18.3 1.8 26.5 5L251 365.5c3.3 8.2 5 17.2 5 26.5c0 39.8-32.2 72-72 72H98.4l.7-.9c11.6-16.9 17.1-38.6 13.8-60c-.5-3.6-.8-7.3-.8-11.1c0-39.8 32.2-72 72-72zM455 23L204.3 273.7c-6.6-1.1-13.4-1.7-20.3-1.7c-66.3 0-120 53.7-120 120c0 6.2 .5 12.4 1.4 18.4C68.1 428.2 56.1 448 38 448H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H184c66.3 0 120-53.7 120-120c0-6.9-.6-13.7-1.7-20.3L553 121c27-27 27-70.9 0-97.9s-70.9-27-97.9 0z" />
                </svg>
                <div>{t('goToTripDesigner')}</div>
              </div>
            </KoobButton>
          )}

          {location.pathname.includes('/compose') &&
            (priceLoading || globalTripPrice !== undefined) ? (
            <div
              id="navbar-trip-designer-price"
              className="flex align-baseline items-center flex-row gap-x-3"
            >
              {globalTripCurrency !== 'mismatch' && (
                <p className="text-gray-400">
                  <Tooltip tip={t('total_details')}>
                    <i className="fa-solid fa-circle-info" />
                  </Tooltip>
                </p>
              )}

              {priceLoading ? (
                <div>
                  <KoobPlaceholder className="w-40 h-6 rounded" />
                  <KoobPlaceholder className="mt-1 w-28 h-4 rounded" />
                </div>
              ) : (
                <div>
                      {globalTripCurrency != null ?
                        globalTripCurrency !== 'mismatch' ? (
                    <>
                      <div className="leading-none text-lg text-orange-600 font-semibold">
                        {t('total', {
                          price: formatCurrency({
                            amount: globalTripPrice,
                            currency: globalTripCurrency,
                          }),
                        })}
                      </div>

                      <div className="text-sm text-gray-600 font-medium">
                        {formatCurrency({
                          amount: globalTripPrice / tripFilters?.maxPax ?? 1,
                          currency: globalTripCurrency,
                        })}{' '}
                        / PAX
                      </div>
                    </>
                  ) : (
                    <div className="flex space-x-2 items-center text-lg font-semibold leading-none">
                      <i className="fa fa-exclamation-circle text-red-600" />
                      <div className="text-lg text-gray-500">
                        {t('misMatchCurrency')}
                      </div>
                    </div>
                        ) : null}
                </div>
              )}
            </div>
          ) : null}

          <KoobButton
            onClick={() => {
              resetContext();
              navigate('/');
            }}
            color="secondary"
          >
            {t('exit')}
          </KoobButton>
        </>
      ) : null}
    </div>
  ) : null;
};

export default GoToTrip;
