import { cva } from "class-variance-authority";

const alert = cva([
  'flex items-center p-4 mb-4 border-l-4 rounded-lg',
], {
  variants: {
    variant: {
      'info': 'bg-blue-100 border-blue-500 text-blue-700',
      'success': 'bg-green-100 border-green-500 text-green-700',
      'warning': 'bg-yellow-100 border-yellow-500 text-yellow-700',
      'error': 'bg-red-100 border-red-500 text-red-700',
    }
  }
});

export default function KoobAlert({ icon, title, description, variant = 'info' }) {
  return (
    <div className={alert({ variant })}>
      {icon && (
        <div className="flex-shrink-0 mr-3">
          <i className={['fal fa-2x', icon].join(' ')} />
        </div>
      )}
      <div className="flex-1">
        {title && (
          <h3 className="text-lg font-medium">
            {title}
          </h3>
        )}
        {description && (
          <div className="text-sm opacity-90">
            {description}
          </div>
        )}
      </div>
    </div>
  );
}
