const programMarker = (mainColor, fillColor) => {
  const svgContent = `
  <svg width="45px" height="60px" viewBox="0 0 36 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icons / Maps / Place experience Active</title>
    <defs>
      <path d="M18 4C10.26 4 4 10.26 4 18c0 10.5 14 26 14 26s14 -15.5 14 -26c0 -7.74 -6.26 -14 -14 -14Z" id="path-1"/>
      <filter x="-30.4%" y="-16.2%" width="160.7%" height="143.7%" filterUnits="objectBoundingBox" id="filter-2">
        <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
        <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1"/>
      </filter>
      <filter color-interpolation-filters="auto" id="filter-3">
        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.F5700A 0"/>
      </filter>
    </defs>
    <g id="Icons-/-Maps-/-Place-experience-Active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Path">
        <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"/>
        <use fill="${mainColor}" fill-rule="evenodd" xlink:href="#path-1"/>
      </g>
      <g id="Filled-/-maps-/-place" transform="translate(8 9)" filter="url(#filter-3)">
        <g>
        <path fill="${fillColor}" d="M5.357 0c0.196 0 0.357 0.161 0.357 0.357v2.5h8.571V0.357c0 -0.196 0.161 -0.357 0.357 -0.357s0.357 0.161 0.357 0.357v2.5h2.143c1.576 0 2.857 1.281 2.857 2.857v14.286c0 1.576 -1.281 2.857 -2.857 2.857H2.857c-1.576 0 -2.857 -1.281 -2.857 -2.857V5.714c0 -1.576 1.281 -2.857 2.857 -2.857h2.143V0.357c0 -0.196 0.161 -0.357 0.357 -0.357zm13.929 8.571H0.714v11.429c0 1.183 0.96 2.143 2.143 2.143h14.286c1.183 0 2.143 -0.96 2.143 -2.143V8.571zm-14.286 -3.214V3.571H2.857c-1.183 0 -2.143 0.96 -2.143 2.143v2.143h18.571v-2.143c0 -1.183 -0.96 -2.143 -2.143 -2.143h-2.143v1.786c0 0.196 -0.161 0.357 -0.357 0.357s-0.357 -0.161 -0.357 -0.357V3.571H5.714v1.786c0 0.196 -0.161 0.357 -0.357 0.357s-0.357 -0.161 -0.357 -0.357zm6.732 7.451c-0.143 -0.201 -0.442 -0.196 -0.58 0.009l-2.643 3.817c-0.063 0.089 -0.156 0.143 -0.263 0.152s-0.21 -0.027 -0.286 -0.103L6.772 15.491c-0.156 -0.156 -0.415 -0.134 -0.545 0.045l-2.277 3.183c-0.17 0.237 0 0.563 0.29 0.563h11.5c0.29 0 0.46 -0.33 0.29 -0.567l-4.299 -5.906zm-1.17 -0.402c0.42 -0.607 1.313 -0.616 1.746 -0.022L16.607 18.299c0.513 0.71 0.009 1.701 -0.866 1.701H4.246c-0.871 0 -1.379 -0.987 -0.871 -1.692l2.277 -3.183c0.384 -0.54 1.161 -0.603 1.629 -0.134l0.888 0.888 2.393 -3.469zM4.286 12.143a0.714 0.714 0 1 0 1.429 0 0.714 0.714 0 1 0 -1.429 0zm0.714 1.429a1.429 1.429 0 1 1 0 -2.857 1.429 1.429 0 1 1 0 2.857z"/>
        </g>
      </g>
    </g>
  </svg>`;

  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
}

export default programMarker;