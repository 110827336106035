export default function KoobLabeledIcon({ icon, label, children }) {
  return (
    <div className="flex space-x-1.5 items-center">
      <i className={[icon, 'text-gray-700'].join(' ')} />
      <div className="text-gray-500">
        {children ?? label}
      </div>
    </div>
  )
}
