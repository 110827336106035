const airMarker = (mainColor, fillColor) => {
  const svgContent = `
  <svg width="45px" height="60px" viewBox="0 0 36 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icons / Maps / Place experience Active</title>
    <defs>
      <path d="M18 4C10.26 4 4 10.26 4 18c0 10.5 14 26 14 26s14 -15.5 14 -26c0 -7.74 -6.26 -14 -14 -14Z" id="path-1"/>
      <filter x="-30.4%" y="-16.2%" width="160.7%" height="143.7%" filterUnits="objectBoundingBox" id="filter-2">
        <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
        <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1"/>
      </filter>
      <filter color-interpolation-filters="auto" id="filter-3">
        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.F5700A 0"/>
      </filter>
    </defs>
    <g id="Icons-/-Maps-/-Place-experience-Active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Path">
        <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"/>
        <use fill="${mainColor}" fill-rule="evenodd" xlink:href="#path-1"/>
      </g>
      <g id="Filled-/-maps-/-place" transform="translate(8 9)" filter="url(#filter-3)">
        <g>
        <path fill="${fillColor}" d="M19.538 7.681c0.264 0.333 0.462 0.743 0.462 1.208 0 0.934 -0.75 1.635 -1.451 2.056 -0.733 0.441 -1.642 0.722 -2.358 0.722H12.705l-3.014 5.271c-0.295 0.521 -0.847 0.84 -1.448 0.84h-1.951c-0.767 0 -1.271 -0.705 -1.069 -1.417L6.566 11.667H4.722l-1.333 1.778C3.179 13.726 2.85 13.889 2.5 13.889H1.043C0.467 13.889 0 13.424 0 12.816c0 -0.066 0.014 -0.163 0.04 -0.257L1.089 8.889 0.04 5.219C0.014 5.125 0 5.028 0 4.931c0 -0.576 0.467 -1.042 1.043 -1.042H2.5c0.35 0 0.679 0.163 0.889 0.444L4.722 6.111h1.844L5.222 1.416C5.021 0.707 5.524 0 6.292 0h1.951c0.601 0 1.153 0.32 1.448 0.84L12.705 6.111h3.486c0.688 0 1.632 0.292 2.361 0.736 0.368 0.226 0.719 0.503 0.986 0.833zm-1.563 0.118c-0.601 -0.365 -1.316 -0.576 -1.785 -0.576H12.382c-0.201 0 -0.385 -0.108 -0.483 -0.281L8.726 1.391c-0.097 -0.173 -0.281 -0.28 -0.483 -0.28h-1.951l1.545 5.403c0.045 0.167 0.014 0.347 -0.09 0.486 -0.108 0.139 -0.271 0.222 -0.444 0.222H4.444c-0.205 0 -0.34 -0.083 -0.444 -0.222L2.5 5H1.133l1.067 3.736c0.029 0.101 0.029 0.205 0 0.306L1.133 12.778H2.5l1.5 -2c0.104 -0.139 0.24 -0.222 0.444 -0.222h2.858c0.174 0 0.337 0.083 0.444 0.222 0.104 0.139 0.135 0.319 0.09 0.486L6.292 16.667h1.951c0.201 0 0.385 -0.108 0.483 -0.281l3.174 -5.549c0.097 -0.174 0.281 -0.281 0.483 -0.281h3.809c0.472 0 1.188 -0.205 1.785 -0.563 0.628 -0.378 0.913 -0.788 0.913 -1.104 0 -0.142 -0.059 -0.344 -0.219 -0.514 -0.16 -0.198 -0.399 -0.399 -0.694 -0.576zM9.208 1.115l-0.483 0.276z"/>
        </g>
      </g>
    </g>
  </svg>`;

  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
}

export default airMarker;