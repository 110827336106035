import React, { useCallback, useEffect, useState } from 'react';
import { MdRemoveRedEye, MdStar } from 'react-icons/md';
import { Input, Text } from '../../../../ui';
import { LocationSearchableSelect } from '../../../../components/Fields';
import FilterHotels from '../../../../components/FilterHotels';
import { Button, DataTable } from '../../../../components';
import { Stack } from '@koob/margaret';
import { gql, useQuery } from '@apollo/client';
import { useApp, useSearchParams, useSnack } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { useTheme } from 'styled-components';

const GET_HOTELS = gql`
  query getHotels(
    $organizationId: ID
    $discarded: Boolean
    $state: String
    $stars: Int
    $location: ID
    $kindsIds: [ID!]
    $endCursor: String
    $toconnectionrequestId: ID
  ) {
    hotels(
      organizationId: $organizationId
      state: $state
      discarded: $discarded
      stars: $stars
      locationId: $location
      kindsIds: $kindsIds
      first: 50
      after: $endCursor
    ) {
      edges {
        node {
          id
          displayName
          stars
          city {
            title
            id
          }
          country {
            alpha2
            title
            id
          }
          toConnectionRequestHotels(
            toconnectionrequestId: $toconnectionrequestId
          ) {
            nodes {
              id
              margin
              isMarginPercent
              enabled
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const GET_LOCATIONS_SEARCH = gql`
  query locationsSearch($search: String!) {
    locationsSearch(search: $search) {
      hotels {
        nodes {
          id
          displayName
        }
      }
      cities {
        nodes {
          id
          title
        }
      }
      countries {
        nodes {
          id
          title
        }
      }
      regions {
        nodes {
          id
          title
        }
      }
    }
  }
`;

export default function HotelsBrowser({
  hotels,
  setHotels,
  isHotelSelected,
  selectAllShownHotels,
  onAddHotel,
  globalMargin,
  setGlobalMargin,
  isGlobalMarginPercent,
  setIsGlobalMarginPercent,
}) {
  const { t } = useTranslation('toConnectionRequest');
  const { currentOrganizationId } = useApp();
  const { requestId } = useParams();
  const [filters, setFilters] = useState({});
  const [{ kindsIds, stars }] = useSearchParams();
  const { notify } = useSnack();
  const [debouncedFilters, setDebouncedFilters] = useState({});
  const theme = useTheme();

  useDebounce(
    () => {
      setDebouncedFilters(filters);
    },
    500,
    [filters],
  );

  const { data, fetchMore, refetch } = useQuery(GET_HOTELS, {
    variables: {
      organizationId: currentOrganizationId,
      state: 'available',
      discarded: false,
      ...debouncedFilters,
      stars: Boolean(stars) ? Number(stars) : undefined,
      kindsIds: Boolean(kindsIds)
        ? (kindsIds ?? []).map(({ value }) => value)
        : undefined,
      toconnectionrequestId: requestId,
    },
  });

  useEffect(() => refetch(), [debouncedFilters, refetch]);

  const handleLoadNextPage = () => {
    try {
      fetchMore({
        variables: {
          endCursor: data?.hotels?.pageInfo?.endCursor,
        },
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  const updateFilterValue = useCallback(
    (property, value) => {
      setFilters(current => ({
        ...current,
        [property]: value.id,
      }));
    },
    [setFilters],
  );

  const decodeId = idKoob => {
    const decoded = atob(idKoob.replace('\n', ''));
    return parseInt(decoded.substring(decoded.lastIndexOf('/') + 1));
  };

  useEffect(() => {
    if (data?.hotels?.edges) {
      let formattedHotels = data.hotels.edges.map(({ node }) => ({
        id: '' + decodeId(node?.id),
        displayName: node?.displayName,
        country: node?.country?.alpha2,
        city: node?.city?.title,
        stars: node.stars,
        margin: null,
        isMarginPercent: null,
      }));

      setHotels(formattedHotels);
    }
  }, [data, setHotels]);

  const headings = [
    {
      slug: 'displayName',
      label: t('hotelName'),
      width: '100px',
      cannotBeReordered: true,
    },
    {
      slug: 'country',
      label: t('countryName'),
      width: '60px',
      cannotBeReordered: true,
    },
    {
      slug: 'city',
      label: t('cityName'),
      width: '100px',
      cannotBeReordered: true,
    },
    {
      slug: 'stars',
      label: t('starsName'),
      width: '50px',
      cannotBeReordered: true,
    },
    {
      slug: 'selected',
      label: t('enabled'),
      width: '40px',
      cannotBeReordered: true,
    },
  ];

  const tableHotels = hotels?.map(node => ({
    id: node?.id,
    displayName: {
      value: node?.displayName,
    },
    country: {
      value: node?.country,
    },
    city: {
      value: node?.city,
    },
    stars: {
      value: node?.stars,
      render: () => (
        <div className="flex">
          {[...Array(node?.stars ?? 0)].map((_, i) => (
            <MdStar size={24} color={theme.yellow} key={i}/>
          ))}
        </div>
      ),
    },
    selected: {
      render: () => (
        <button
          className="border border-gray-200 rounded-full p-2 text-lg leading-none"
          onClick={() => {
            onAddHotel(node);
          }}
        >
          {isHotelSelected(node?.id) ? (
            <MdRemoveRedEye className="text-orange-500" />
          ) : (
            <MdRemoveRedEye className="text-gray-400" />
          )}
        </button>
      ),
    },
  }));

  return (
    <div>
      <Text type="h3">{t('browseHotels')}</Text>

      <div className="my-4 p-5 border rounded">
        <div className="mb-2 flex justify-between">
          <h6 className="font-medium">
            {t('globalMargin', { unit: isGlobalMarginPercent ? '(%)' : '' })}
          </h6>

          <div className="flex items-center space-x-1">
            <input
              type="checkbox"
              checked={isGlobalMarginPercent}
              onChange={() => setIsGlobalMarginPercent(!isGlobalMarginPercent)}
            />
            <p className="text-sm">{t('usePercentMargin')}</p>
          </div>
        </div>

        <Input
          type="number"
          min={0}
          value={globalMargin}
          onChange={v => setGlobalMargin(parseFloat(v.target.value))}
        />
      </div>

      <div className="flex justify-between">
        <LocationSearchableSelect
          placeholder={t('locationsSearch', { ns: 'hotel' })}
          query={GET_LOCATIONS_SEARCH}
          pathToEdges={['locationsSearch']}
          renderSelectedOption={value => value?.title}
          onChange={value => updateFilterValue('location', value)}
          wrapperStyle={{
            marginLeft: 0,
            width: 350,
            height: 42,
            '--location-search-trigger-height': `${42}px`,
          }}
          inputStyle={{ width: 350, height: 42 }}
          deletable={true}
        />
        <div className="flex align-end justify-center">
          <div style={{ marginRight: 5 }}>
            <button
              className="border border-gray-200 rounded-full p-2 text-lg leading-none"
              onClick={() => {
                if (window.confirm(t('selectAllHotelConfirmationMessage'))) {
                  selectAllShownHotels(hotels);
                  notify(t('selectedAllHotelsuccess'));
                }
              }}
            >
              <MdRemoveRedEye class="text-gray-400" />
            </button>
          </div>
          <FilterHotels showLocation={false} showKind={false} />
        </div>
      </div>

      <DataTable headings={headings} data={tableHotels} isSearchable={false} />

      {data?.hotels?.pageInfo?.hasNextPage && (
        <Stack alignX="center">
          <Button variant="simple" onClick={handleLoadNextPage}>
            {t('misc:seeMore')}
          </Button>
        </Stack>
      )}
    </div>
  );
}
