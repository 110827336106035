import {Button} from "@koob/margaret";
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {markAllAsRead, markAsRead} from 'api/node/notifications';
import {getTripConversation} from 'api/node/tripConversation';
import {Popover} from '@headlessui/react';
import {useNotifications} from 'hooks';
import NotificationType from "../constants/NotificationType";
import { encodeId } from 'utils';

const BellIconLabel = ({ children }) => {
  return (
    <div
      className="
        absolute inline-flex items-center justify-center w-6 h-6 text-xs font-medium text-white bg-orange-500 border-2 whitespace-nowrap
        border-white rounded-full -top-2 -right-2 p-3
      "
    >
      {children}
    </div>
  );
};

const NotificationsDropdown = () => {
  const { t } = useTranslation('notifications');
  const navigate = useNavigate();

  const { notifications, reloadNotifications, loading } = useNotifications();

  const onNotificationClick = async (notification) => {
    try {
      await markAsRead({ notificationId: notification.id });

      let relatedId = notification.relatedObject;

      switch (notification.notificationType) {
        case NotificationType.TRIP_HELP_REQUESTED:
          const response = await getTripConversation({
            conversationId: notification.relatedObject,
          });
          relatedId = response?.data?.trip;
          break;
        default:
          break;
      }

      reloadNotifications();
      navigate(getLinkByType(notification.notificationType, relatedId));
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleMarkAllAsRead = async () => {
    await markAllAsRead();
    reloadNotifications();
  };

  const unreadNotificationsCount = notifications?.filter(
    (n) => n.read === false,
  ).length;

  const maxUnreadNotificationsValue = 99;

  const getIconByType = (type) => {
    switch (type) {
      case NotificationType.TRIP_HELP_REQUESTED:
        return 'far fa-hands-helping';
      case NotificationType.TRIP_HELP_ASSISTANT:
        return 'fa-regular fa-head-side-headphones';
      case NotificationType.TRIP_NEW_VERSION_AVAILABLE:
        return 'fa-regular fa-download';
      case NotificationType.TRIP_BOOKING_CONFIRMED:
        return 'fa-regular fa-bags-shopping';
      case NotificationType.BOOKING_NEW_REQUEST_HOTEL:
      case NotificationType.BOOKING_NEW_CONFIRMATION_HOTEL:
      case NotificationType.BOOKING_HOTEL_UPDATED:
      case NotificationType.BOOKING_HOTEL_IMPORTANT_UPDATE:
      case NotificationType.BOOKING_HOTEL_CANCELED:
      case NotificationType.UPDATE_HOTEL:
      case NotificationType.BOOKING_NEW_REQUEST_EXPERIENCE:
      case NotificationType.BOOKING_NEW_CONFIRMATION_EXPERIENCE:
      case NotificationType.BOOKING_EXPERIENCE_UPDATED:
      case NotificationType.BOOKING_EXPERIENCE_IMPORTANT_UPDATE:
      case NotificationType.BOOKING_EXPERIENCE_CANCELED:
      case NotificationType.UPDATE_EXPERIENCE:
        return 'fa-regular fa-ticket';
      case NotificationType.TO_CONNECTION_REQUEST_HOTEL_REQUESTED:
        return 'fa-regular fa-hotel';
      case NotificationType.TO_CONNECTION_REQUEST_EXPERIENCE_REQUESTED:
        return 'fa-regular fa-layer-group';
      default:
        return '';
    }
  };
  const getLinkByType = (type, relatedId) => {
    let suffix = ''
     if (
      [
        NotificationType.UPDATE_EXPERIENCE,
        NotificationType.UPDATE_HOTEL,
      ].includes(type)
    ) {
      suffix = '/chat';
    }

    switch (type) {
      case NotificationType.TRIP_HELP_REQUESTED:
        return '/trip-designer/library/drafts/' + relatedId;
      case NotificationType.TRIP_HELP_ASSISTANT:
        return '/trip-designer/library/drafts/' + relatedId;
      case NotificationType.TRIP_NEW_VERSION_AVAILABLE:
        return '/trip-designer/library/drafts/' + relatedId;
      case NotificationType.TRIP_BOOKING_CONFIRMED:
        return '/trip-designer/library/trips/' + relatedId;
      case NotificationType.BOOKING_NEW_REQUEST_HOTEL:
      case NotificationType.BOOKING_NEW_CONFIRMATION_HOTEL:
      case NotificationType.BOOKING_HOTEL_UPDATED:
      case NotificationType.BOOKING_HOTEL_IMPORTANT_UPDATE:
      case NotificationType.BOOKING_HOTEL_CANCELED:
      case NotificationType.UPDATE_HOTEL:
      case NotificationType.BOOKING_NEW_REQUEST_EXPERIENCE:
      case NotificationType.BOOKING_NEW_CONFIRMATION_EXPERIENCE:
      case NotificationType.BOOKING_EXPERIENCE_UPDATED:
      case NotificationType.BOOKING_EXPERIENCE_IMPORTANT_UPDATE:
      case NotificationType.BOOKING_EXPERIENCE_CANCELED:
      case NotificationType.UPDATE_EXPERIENCE:
        return (
          '/bookings/partner-bookings/by-day/undefined/' +
          encodeId('AdminBooking', relatedId) + suffix
        );
      case NotificationType.TO_CONNECTION_REQUEST_HOTEL_REQUESTED:
        return '/to-connection-request/' + relatedId + '/hotels/';
      case NotificationType.TO_CONNECTION_REQUEST_EXPERIENCE_REQUESTED:
        return '/to-connection-request/' + relatedId + '/experiences/';
      default:
        break;
    }
  };
  return (
    <Popover className="relative">
      <Popover.Button className="border-none focus:outline-none">
        <div className="relative">
          <i className="far fa-bell text-3xl text-gray-400 hover:text-gray-500 transition" />
          {loading && (
            <BellIconLabel>
              <i className="far fa-refresh animate-spin" />
            </BellIconLabel>
          )}
          {unreadNotificationsCount > 0 && (
            <BellIconLabel>
              {unreadNotificationsCount > maxUnreadNotificationsValue ? maxUnreadNotificationsValue + '+' : unreadNotificationsCount}
            </BellIconLabel>
          )}

        </div>
      </Popover.Button>

      <Popover.Panel className="fixed right-20 top-20 z-10">
        {({ close }) => (
          <div className={"bg-white w-[500px] rounded-md shadow-k p-3"}>
            <div className={"flex justify-center mb-3"}><Button onClick={handleMarkAllAsRead}>Mark all as read</Button></div>
            <div className="h-72 overflow-y-scroll">
              {notifications?.length > 0 ? (
                <div>
                  {notifications?.map((notification) => (
                    <div
                      key={notification.id}
                      className="border-b cursor-pointer"
                      onClick={() => {
                        onNotificationClick(notification);
                        close();
                      }}
                    >
                      <div className="hover:bg-gray-50 py-3 px-4 transition rounded">
                        <div className="flex space-x-3 items-center">
                          <div className="w-8">
                            <i
                              className={[
                                getIconByType(notification.notificationType),
                                'text-2xl',
                                notification.read
                                  ? 'text-gray-400'
                                  : 'text-orange-500',
                              ].join(' ')}
                            />
                          </div>

                          <div className="w-full flex justify-between items-center">
                            <div className="text-sm font-medium text-gray-700">
                              {t('types.' + notification.notificationType)}
                            </div>

                            <div className="text-xs text-gray-400">
                              {moment(notification.createdAt).fromNow()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="h-full flex justify-center items-center">
                  <div className="text-gray-400 text-center">
                    <i className="far fa-inbox text-4xl mb-3"/>
                    <div className="text-lg">{t('emptyState.title')}</div>
                    <div className="text-sm">{t('emptyState.description')}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default NotificationsDropdown;
